import styled from "styled-components";
import { ReactComponent as CopyIcon } from "../../../../assets/copy-icon.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/check-icon.svg";
import { Collapse, Row } from "antd";
const { Panel } = Collapse;

export const StyledCollapse = styled(Collapse)`
border: 1px solid #F0F0F0;
background: #fff;
min-height: 84px;
border-radius: 8px 0px 0px 0px;
border: 1px 0px 0px 0px;
opacity: 0px;
button{
font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 15.6px;
text-align: center;
}
`;

export const EmptyAccount = styled.div`
  h1 {
    font-family: Athletics;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 0;
  }

  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin: 0;
  }
  button{
    margin-top: 10px;
  }
`;

export const AccountDetailsContainer = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
padding: 16px;
justify-content: space-between;
p{
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16px;
text-align: left;
};
h4{
font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: -0.02em;
text-align: left;
}
`;




export const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    h3 {
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
    }

    p {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #565C69;
        margin-top: 0;
    }
`;


export const Error = styled.span`
    color: #FF3333;
    font-size: 12px;
    font-weight: 200px;
`;


export const AccountDetails = styled(Row)`
//   width: 100%;
//   @media (min-width: 768px) {
//     width: 75%;
//   }
//   margin: 20px 0;
`

export const AccountDetailsKey = styled.h5`
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #484848;
`

export const AccountDetailsValue = styled.p`
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`


export const ReceiveWrapper = styled.div`
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 8px;
padding: 7px;
margin: 3px;
margin-top:15px;
cursor: pointer;
:hover{
background: #F9F9F9;
}
h3{
  font-size: 20px;
  font-weight: 700;


};
p{
  color: #606673;
  font-weight: 400;
  font-size: 12px;


}
.box-item{
    padding: 10px 13px;
}
`

interface PanelWrapperProps {
  open?: boolean;
}

export const Content = styled(Collapse) <PanelWrapperProps>`
  width: 100%;
  border: 0;

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    display: none;
  }
`;

interface panelHeightProps {
  currencyCode?: string
}

export const SlideDown = styled(Panel) <panelHeightProps>`
  background-color: #FFFFFF;

  .ant-collapse-expand-icon {
    display: none;
    border: none !important;
  }
  .ant-collapse-content {
    border: none;
  }


  .ant-collapse > .ant-collapse-item {
    border: none !important;
  }

  .ant-collapse-header-text {
    flex: auto;
    position: relative !important;
    left: -15px !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 5px;
    position: relative;
  }

  button {
    margin-top: 40px;
  }
`;

export const Heading = styled.h3`
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px;
`;


export const Wrapper = styled.div`
.currency-input{
    margin-top:40px;
};

button{
    margin-top: 50px;
}
`;

export const Line = styled.div`
border-bottom: 1px solid #F0F0F0;
margin: 0;
`;

export const RequestAccountWrapper = styled.div`
border-radius: 8px;
border: 1px 0px 0px 0px;
border: 1px solid #F0F0F0;
padding: 10px;
h1, p{
    margin: 0;
}
p{
 color: #565C69;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16px;
text-align: left;
};
h1{
font-family: Athletics;
font-size: 28px;
font-weight: 700;
line-height: 34px;
letter-spacing: -0.02em;
text-align: left;

};
button{
    margin-top: 10px;
}
`;

export const PendingWrapper = styled.div`
padding: 16px;
border: 1px solid #F0F0F0;
opacity: 0px;
h1{
font-family: Athletics;
font-size: 18px;
font-weight: 500;
line-height: 28px;
text-align: left;
};
p{
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16px;
text-align: left;
color: #565C69;
};
<<<<<<< HEAD
<<<<<<< HEAD
=======
>>>>>>> 12d790ee (update virtual account for usd card)
`;

export const Copy = styled(CopyIcon)`
cursor: pointer;
margin-bottom: 10px;
`
export const Check = styled(CheckIcon)`
cursor: pointer;
margin-bottom: 10px;
<<<<<<< HEAD
=======

>>>>>>> aa619806 (show different account status)
=======
>>>>>>> 12d790ee (update virtual account for usd card)
`