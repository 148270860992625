import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserData {
    amount: number;
    convertedAmount: number;
    loading: boolean;
    fees: number;
    countries?: any;
    currency: string;
    buyingRate: number;
    rate: number
    selectedCountry?: string | null;
    availableCurrency?: any;
    destinationCurrency?: string;
    sourceCurrency?: string | number
}

interface Beneficiary {
    id?: string;
    beneficiaryName?: string;
    dob?: string;
    country?: string;
    accountNumber?: string;
    bankName?: string;
    disabled?: boolean;
}

interface FormDataState {
    beneficiaries: Beneficiary[];
    selectedBeneficiary: any;
    userData: UserData;
    activeCurrency: string;
}

const initialState: FormDataState = {
    beneficiaries: [],
    selectedBeneficiary: null,
    userData: {
        amount: null,
        convertedAmount: null,
        loading: true,
        fees: null,
        currency: '',
        buyingRate: null,
        rate: null,
        sourceCurrency: ''
    },
    activeCurrency: "Same currency", 
};

const formDataSlice = createSlice({
    name: 'formData',
    initialState,
    reducers: {
        updateFormData(state, action: PayloadAction<Partial<FormDataState>>) {
            return { ...state, ...action.payload };
        },
        addWalletData(state, action: PayloadAction<Partial<FormDataState>>) {
            return { ...state, ...action.payload };
        },
        SavePaymentData(state, action: PayloadAction<Partial<UserData>>) {
            state.userData = { ...state.userData, ...action.payload };
        },
        addBeneficiary(state, action: PayloadAction<Beneficiary>) {
            state.beneficiaries.push(action.payload);
        },
        setSelectedBeneficiary(state, action: PayloadAction<Beneficiary>) {
            state.selectedBeneficiary = action.payload;
        },
        setBeneficiaries(state, action: PayloadAction<Beneficiary[]>) {
            state.beneficiaries = action.payload;
        },
        clearFormData(state) {
            return initialState;
        },
        setActiveCurrency(state, action: PayloadAction<string>) {
            state.activeCurrency = action.payload;
        },
        clearUserData(state) {
            return initialState
        },
    },
});

export const { updateFormData, addBeneficiary, setSelectedBeneficiary, setBeneficiaries, clearFormData, SavePaymentData, setActiveCurrency, clearUserData, addWalletData } = formDataSlice.actions;
export default formDataSlice.reducer;