import styled from "styled-components";


type Props = {
  type: string;
  active: string;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: #313131;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
`;

export const ToggleItem = styled.span<Props>`
//   border-radius: 8px;
  border: 1px solid #E8E8E8;
  padding: 10px 16px;
  background-color: ${({type, active}) => type === active ? "#E8E8E8" : "#FFFFFF"};

  &:first-child {
      border-radius: 8px 0 0 8px;
      border: 1px solid #E8E8E8;

    }

    &:nth-child(2)
    border-radius: none !important;
    border-left: 0;
  }

    &:last-child {
      border-radius: 0 8px 8px 0;
      border-left: 0;
    }
`;
