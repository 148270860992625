import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 15.6px;
  background-color: #FFFFFF;

  svg {
    height: 16px;
    margin-left: 5px;
  }

  div[class~="ant-dropdown-trigger"] {
    width: 103px;
    height: 40px;
    cursor: pointer;
    border: 1px solid #000000;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;