import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchTransactions,
  getAllFees,
  getAllTimePayoutData,
  getDefaultExchangeRate,
  getFilteredPayoutRequests,
  getPendingBalance,
  getRequestFee,
  getSettlementBalance,
  requestPayout,
  getCollectionBreakDown,
} from "./settlementsAPI";

export interface SettlementState {
  settlements: any[];
  payoutRequests: any[];
  fees: any[];
  status: "idle" | "loading" | "failed" | "succeeded";
  isLoading: false;
  error: string | null;
  settlementBalance: number;
  pendingBalance: any;
  walletId: any;
  totalPaid: any;
  collections: any;
}

const initialState: SettlementState = {
  settlements: [],
  payoutRequests: [],
  status: "idle",
  isLoading: false,
  error: null,
  settlementBalance: 0,
  pendingBalance: null,
  walletId: null,
  totalPaid: null,
  fees: [],
  collections: [],
};

export const settlementsInfo = createAsyncThunk(
  "merchant/settlements",
  async ({ walletId }: { walletId: string | number }) =>
    fetchTransactions({ walletId }),
);

export const settlementsPendingBalance = createAsyncThunk(
  "merchant/settlements/pending/balance",
  async ({ businessId }: { businessId: string | number }) =>
    getPendingBalance({ businessId }),
);

export const settlementsPaid = createAsyncThunk(
  "merchant/settlements/total/paid",
  async ({ businessId }: { businessId: string | number }) =>
    getAllTimePayoutData({ businessId }),
);

export const settlementFees = createAsyncThunk(
  "merchant/settlements/all/fees",
  async () => getAllFees(),
);

export const settlementFeeByCountry = createAsyncThunk(
  "merchant/settlements/all/fees",
  async ({ countryCode, feeType }: { countryCode: string; feeType: string }) =>
    getRequestFee({ countryCode, feeType }),
);

export const settlementsBalance = createAsyncThunk(
  "merchant/settlements/balance",
  async ({ businessId }: { businessId: number, businessCurrency: string }) =>
    getSettlementBalance({ businessId }),
);

export const collectionBreakDown = createAsyncThunk(
  "merchant/collection/breakdown",
  async ({ businessId }: { businessId: string | number }) =>
    getCollectionBreakDown({ businessId }),
);

export const requestSettlement = createAsyncThunk(
    "merchant/settlements/request",
    async (
      payload: any,
      { rejectWithValue }
    ) => {
      try {
        return await requestPayout({ message: payload });
      } catch (err) {
        return rejectWithValue(err.response?.data ?? 'An unknown error occurred');
      }
    }
  );

export const filteredPayoutRequests = createAsyncThunk(
  "merchant/payouts/request",
  async ({ filter }: { filter: any }) => getFilteredPayoutRequests(filter),
);

export const defaultExchangeRate = createAsyncThunk(
  "rate/exchange",
  async ({
    sourceCurrency,
    amount,
    destinationCurrency,
  }: {
    sourceCurrency: string;
    amount: string | number;
    destinationCurrency: string;
  }) =>
    getDefaultExchangeRate({
      sourceCurrency,
      amount,
      destinationCurrency,
    }),
);

export const settlementsSlice = createSlice({
  name: "settlements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(settlementsInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(settlementsInfo.fulfilled, (state, action) => {
        state.status = "idle";
        state.settlements = action.payload;
      })
      .addCase(settlementsInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(settlementsPendingBalance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(settlementsPendingBalance.fulfilled, (state, action) => {
        state.status = "idle";
        state.pendingBalance = action.payload;
      })
      .addCase(settlementsBalance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(settlementsBalance.fulfilled, (state, action) => {
        state.status = "idle";
        const businessCurrency = action.meta?.arg?.businessCurrency
        const walletBalance = action.payload.find((p: {currency: string}) => p.currency === businessCurrency)
        state.settlementBalance = walletBalance?.availableBalance;
        state.walletId = walletBalance?.id;
      })
      .addCase(requestSettlement.pending, (state) => {
        state.status = "loading";
      })
      .addCase(requestSettlement.fulfilled, (state, action) => {
        state.status = "idle";
        state.payoutRequests.unshift({
          merchantWithdrawRequest: action.payload,
        });
      })
      .addCase(requestSettlement.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(filteredPayoutRequests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(filteredPayoutRequests.fulfilled, (state, action) => {
        state.status = "idle";
        state.payoutRequests = action.payload;
      })
      .addCase(settlementsPaid.fulfilled, (state, action) => {
        state.status = "idle";
        state.totalPaid = action.payload;
      })
      .addCase(settlementFees.fulfilled, (state, action) => {
        state.status = "idle";
        state.fees = action.payload;
      })
      .addCase(collectionBreakDown.pending, (state) => {
        state.status = "loading";
      })
      .addCase(collectionBreakDown.fulfilled, (state, action) => {
        state.status = "idle";
        state.collections = action.payload;
      })
      .addCase(collectionBreakDown.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default settlementsSlice.reducer;
