import React, { ReactNode } from "react";
import { EmptyWrapper, Wrapper } from "./style";
import { ReactComponent as EmptyTransactions } from "./assets/no-transactions.svg";

interface InputProps {
  dataSource?: object[];
  columns?: {
    key: string;
    title: string;
    onClick?: (row: any) => void;
  }[];
  extraEmptyChildren?: ReactNode;
  totalItemCount?: any;
  currentPage?: number;
  showEmptyImg?: boolean;
  onPageChange?: (page: number) => void;
  loading?: boolean;
  handleRowClick?: any;
  icon?: ReactNode;
}



const CustomTable = ({
  extraEmptyChildren,
  currentPage,
  totalItemCount,
  showEmptyImg,
  loading,
  onPageChange,
  handleRowClick, 
  icon,
  ...props
}: InputProps) => {
    const locale = {
        emptyText: (
          <EmptyWrapper>
            {icon || <EmptyTransactions />}
            {extraEmptyChildren}
          </EmptyWrapper>
        ),
      };

  const onRowClick = (row: any) => {
    if (handleRowClick) {
      handleRowClick(row);
    }
  };

  return (
    <Wrapper
      {...props}
      locale={locale}
      pagination={{
        pageSize: 10,
        total: totalItemCount || undefined,
        current: currentPage || undefined,
        hideOnSinglePage: true,
        showSizeChanger: false,
        onChange: onPageChange && onPageChange
      }}
      onRow={(row: any) => ({
        onClick: () => onRowClick(row),
      })}
    />
  );
};

export default CustomTable;