import React, { useContext, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useWindowSize } from "@reach/window-size";
import {
    Body,
    BodyContent,
    Wrapper,
    Overlay,
} from "./style";
import SideMenu from "../common/SideMenu";
import Header from "./components/Header";
import { SuccessAlertContext } from "../../context/SuccessAlertContext";
import SuccessAlert from "../common/alerts/SuccessAlert";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import NetworkErrorAlert from "../common/alerts/NetworkErrorAlert";
import Loader from "../common/Loader";
import { useAppSelector } from "../../app/hooks";
import CopyAlert from "../common/alerts/CopyAlert";
import { CopyAlertContext } from "../../context/CopyAlertContext";
import { routesWithCloseTrue } from "../../utils/routesWithClose";

interface props {
    children: React.ReactElement;
}

const AppShell = ({ children }: props) => {
    const { width } = useWindowSize();
    const location = useLocation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { showAlert, alertMessage } = useContext(
        SuccessAlertContext,
    );
    const { showAlert: copyAlert } = useContext(
        CopyAlertContext,
    );
    const {
        showAlert: showErrorAlert,
        alertMessage: errorAlertMessage,
    } = useContext(NetworkErrorAlertContext);
    const { status: userStatus } = useAppSelector(
        (state) => state.users,
    );
    const { pathname } = useLocation();
    
    const [isExpanded, setIsExpanded] = useState<
        "none" | "min" | "max"
    >(width > 992 ? "max" : "none");

    const onCollapse = () => {
        setIsExpanded("none");
    };

    const onShowSide = () => {
        setIsExpanded("max");
    };

    React.useEffect(() => {
        if (routesWithCloseTrue.includes(pathname) || pathname.includes('/team/role') 
            || pathname.startsWith('/wallets/USD/account-creation')) {
            setIsExpanded('none');
        } else {
            setIsExpanded('max');
        }
    }, [pathname]);
    

    if (userStatus === "loading") {
        return <Loader isLoading isNotFull />;
    }
    return (
        <Wrapper>
            <SideMenu
                onCollapse={onCollapse}
                isExpanded={isExpanded}
                dropdownOpen={dropdownOpen}
                setDropdownOpen={setDropdownOpen}
            />
            <Body $isExpanded={isExpanded}>
                {showErrorAlert && (
                    <NetworkErrorAlert
                        message={errorAlertMessage}
                    />
                )}
                {showAlert && (
                    <SuccessAlert message={alertMessage} />
                )}
                {copyAlert && <CopyAlert />}

                <Header
                    onShowSide={onShowSide}
                    dropdownOpen={dropdownOpen}
                    setDropdownOpen={setDropdownOpen}
                />

                <BodyContent>
                    <Outlet />
                    <div>{children}</div>
                </BodyContent>
            </Body>

            <Overlay
                onClick={() => setIsExpanded("none")}
                $isExpanded={isExpanded}
            />
        </Wrapper>
    );
};

export default AppShell;
