import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import Loader from "../../../components/common/Loader";
import {
  CollectionWrapper,
  CollectionTitle,
  CollectionHeading,
  Currencies,
} from "./style";
import { getCollectionBreakDown } from "../settlementsAPI";
import { useNavigate } from "react-router-dom";

const defaultCurrencies = [
  { source_currency: "NGN", sum: "0.00" },
  { source_currency: "USD", sum: "0.00" },
  { source_currency: "RAND", sum: "0.00" },
  { source_currency: "KES", sum: "0.00" },
];

const Collections = () => {
  const { businessId} = useAppSelector((state) => state.users);
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const navigate = useNavigate()
  
  const updatedCurrencyArray = defaultCurrencies
    .map((currency) => {
      const existingCurrency = selectedCurrency.find(
        (c) =>
          c.source_currency === currency.source_currency,
      );
      return existingCurrency || currency;
    })
    .slice(0, 4);

  const [loading, setLoading] = useState(false);

  const appendCurrencySymbol = (
    source_currency: string,
    sum: string,
  ) => {
    switch (source_currency) {
      case "NGN":
        return `₦${sum.toLocaleString()}`;
      case "USD":
        return `$${sum.toLocaleString()}`;
      case "RAND":
        return `R${sum.toLocaleString()}`;
      case "KES":
        return `K${sum.toLocaleString()}`;
      default:
        return sum;
    }
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        await getCollectionBreakDown({
          businessId: businessId,
        }).then((data) => {
          setSelectedCurrency(data.data);
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <CollectionWrapper>
      <Loader isLoading={loading} />
      
      <CollectionTitle>
        <CollectionHeading>
          Today's collections
        </CollectionHeading>
      </CollectionTitle>

      <Currencies>
        {updatedCurrencyArray.map((currency, index) => (
          <div
            key={index}
            onClick={() => navigate(`/collection/${currency?.source_currency}`)}
            className="item"
          >
            <h1>{currency?.source_currency}</h1>
            <p>
              {appendCurrencySymbol(
                currency?.source_currency,
                currency?.sum,
              )}
            </p>
          </div>
        ))}
      </Currencies>
    </CollectionWrapper>
  );
};

export default Collections;
