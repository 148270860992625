import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getAllWallets,
  getAllVirtualAccounts,
  createNewWallet,
  getAllPayouts,
  getWalletTransactions,
  requestForAccountDetails,
} from "../api";
import api from "../../../app/api";
import axios from "axios";

export interface TransactionsType {
  pageNo?: number;
  totalCount?: number;
  totalPages?: number;
  transactions?: any[];
}
export interface WalletState {
  wallets: any[];
  virtualAccounts: any[];
  loading: boolean;
  status: "idle" | "loading" | "failed" | "succeeded";
  isLoading: false;
  error: string | null;
  payouts: any;
  transactions?: any[];
  requestAccount?: any[];
  getAllPayIns?: any[];
  getAllPayOut?: any[];
}

interface ForeignBankAccountOwner {
    name: string;
    dob: string;
    country: string;
    ownershipPercentage: number;
    foreignBankAccountOwnershipType: "DIRECTOR" | "BENEFICIARY";
}
interface ForeignBankAccountOwner {
    name: string;
    dob: string;
    country: string;
    ownershipPercentage: number;
    foreignBankAccountOwnershipType: "DIRECTOR" | "BENEFICIARY";
}

interface CompanyDetails {
    companyLegalName: string;
    companyRegistrationNumber: string;
    companyCountryIncorporation: string;
    dateOfIncorporation: string;
    companyEnterpriseType: string;
    companyType: string;
    website: string;
    industry: string;
    countryTaxResidency: string;
    companyCity: string;
    companyPostCode: string;
    companyAddressCompany: string;
    flowOfFunds: string;
    depositorIndustries: string;
    beneficiaryIndustries: string;
    requiredCurrency: string;
    sourceCountries: string;
    destinationCountries: string;
    monthlyVolume: number;
    annuallyVolume: number;
    fx: number;
    pepSanction: boolean;
    amlPolicy: boolean;
    businessId: number;
    foreignBankAccountOwnerships: ForeignBankAccountOwner[];
}

interface ForeignBankType{
  currency: string,
  fundAmount: number,
  creditStatus: string,
  reference: string,
  walletId: number | string

}

interface WithdrawTypes{
    currency?: string,
    country?:any
  
  }

  interface rateTypes {
    destinationCurrency: string;
    sourceCurrency: string;
    provider: string;
    amount: any;
    source: string;
}


const initialState: WalletState = {
  wallets: [],
  virtualAccounts: [],
  status: "idle",
  isLoading: false,
  loading: false,
  error: null,
  payouts: null,
  requestAccount: null,
};



export const allWallets = createAsyncThunk(
  "wallets/balance",
  async ({ businessId }: { businessId: string | number }) =>
    getAllWallets({ businessId }),
);

export const createWallet = createAsyncThunk(
  "wallets/balance/new",
  async ({
    businessId,
    currency,
    userId,
  }: {
    businessId: string | number;
    currency: "USD" | "NGN";
    userId: string | number;
  }) => createNewWallet({ businessId, currency, userId }),
);

export const allVirtualAccounts = createAsyncThunk(
  "virtualAccounts/all",
  async ({ businessId }: { businessId: string | number }) =>
    getAllVirtualAccounts({ businessId }),
);

export const payoutsInfo = createAsyncThunk(
  "merchant/payouts/all",
  async ({ filter }: { filter: any }) => {
    const response = await getAllPayouts(filter);
    return response;
  },
);

export const walletTransactions = createAsyncThunk(
  "wallter/transactions",
  async ({ filter }: { filter: any }) => {
    const response = await getWalletTransactions(filter);
    return response;
  },
);

export const requestAccountDetails = createAsyncThunk(
  "wallets/requestAccount",
  async (data) => {
    const response = await requestForAccountDetails(data);
    return response;
  },
);

export const CreateUSDAccount = createAsyncThunk(
    "wallets/merchant-usd-account",
    async ({ data }: { data: CompanyDetails }, { rejectWithValue }) => {
        try {
            const response = await api.post(
                `/merchant/foreign-bank-account-request`,
                data
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response.data.message,
            );
        }
    },
);



export const GetVirtualAccountStatus = createAsyncThunk(
    "wallets/get-virtual-account-status",
    async ({ businessId }: { businessId: string | number }, { rejectWithValue }) => {
        try {
            const response = await api.get(
                `/merchant/foreign-bank-account-request?businessId=${businessId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response.data.message,
            );
        }
    },
);

export const MerchantForeignBankFundingRequest = createAsyncThunk(
    "wallets/foriend-bank-funding-request",
    async ({ message }: { message: any}, { rejectWithValue }) => {
        try {
            const response = await api.post(
                `/wallet/foreign-account-funding-request/v2`,
                message
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response.data.message,
            );
        }
    },
);

export const getBassFee = createAsyncThunk(
    "wallets/merchant-bass-fee",
    async ({ currency }: WithdrawTypes, { rejectWithValue }) => {
        try {
            const response = await api.get(
                `/wallet/fees/get/GB/baasWithdraw?currency=${currency}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || 'An error occurred'
            );
        }
    }
);

export const getReceivingCurrencyByCountry = createAsyncThunk(
    'wallets/getReceivingCurrencyByCountry',
    async (country: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`/merchant/baas/currency/get/currency/active/${country}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'An error occurred');
        }
    }
);
export const getExchangeRate = createAsyncThunk(
    'wallets/get-exchange-rate',
    async ({ destinationCurrency, sourceCurrency, provider, amount, source }: rateTypes, { rejectWithValue }) => {
        try {
            const response = await api.post(`/nucleus/general/exchange`, {
                destinationCurrency,
                sourceCurrency,
                provider,
                amount,
                source
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'An error occurred');
        }
    }
);


export const getReceivingCountries = createAsyncThunk(
    "wallets/merchant-bass-country",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get(`/merchant/wirecountry/get/unique/country`);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || 'An error occurred'
            );
        }
    }
);

export const addBeneficiary = createAsyncThunk(
    'wallets/merchant/merchantbeneficiary',
    async ({ data }: { data: any }, { rejectWithValue }) => {
      try {
        const response = await api.post('/merchant/merchantbeneficiary/v2', data);
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'An error occurred');
      }
    }
  );

export const getBeneficiaries = createAsyncThunk(
    'wallets/merchant/get-beneficiary',
    async ({ businessId }: { businessId: any }, { rejectWithValue }) => {
      try {
        const response = await api.get(`/merchant/merchantbeneficiary/${businessId}/v2`);
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'An error occurred');
      }
    }
  );

export const sendMerchantOTP = createAsyncThunk(
    'wallets/merchant/send-beneficiary-otp',
    async ({ data }: { data: any }, { rejectWithValue }) => {
      try {
        const response = await api.post(`/merchant/bulkmerchantpayment/otp`, data);
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'An error occurred');
      }
    }
  );

export const sendMerchantPayment = createAsyncThunk(
    'wallets/merchant/send-payment',
    async ({ data }: { data: any }, { rejectWithValue }) => {
      try {
        const response = await api.post(`/merchant/bulkmerchantpayment/baas`, data);
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'An error occurred');
      }
    }
  );

export const sendUploadedBASSDocument = createAsyncThunk(
    'wallets/business-doc/uploaded',
    async ({ id, formData }: { id: any, formData: FormData }, { rejectWithValue }) => {
        try {
            const response = await api.post(`/merchant/foreign-bank-account-request/onboarding/attachment/${id}`, formData);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'An error occurred');
        }
    }
);


export const sendToGoogleSheet = createAsyncThunk(
    'googleSheet/sendData',
    async ({ formData }: {formData: any}) => {
      try {
        const { companyName, companyRegistrationNumber, companyCountryIncorporation, website, date, enterPriseType, industry, tax, addressCountry, city, code, 
            flowOfFunds, depositorIndustry, beneficiaryIndustry, requiredCurrency, sourceCountry, destinationCountry, monthlyTransactions
        , annualVolume, fx, directorName, directorDob, directorCountry, name, dob, country, ownershipPercentage, adverseMedia, customerDueDiligence } = formData;
        const formDataToSend = new FormData();
        const findBeneficiary = formData.beneficiaries;
        formDataToSend.append('LegalName', companyName);
        formDataToSend.append('RegistrationNumber', companyRegistrationNumber);
        formDataToSend.append('Link', 'N/A');
        formDataToSend.append('Country_Of_Incorporation', companyCountryIncorporation);
        formDataToSend.append('Date_of_incorporation', date);
        formDataToSend.append('Company_type', enterPriseType);
        formDataToSend.append('Industry', industry);
        formDataToSend.append('Website', website);
        formDataToSend.append('CountryTax', tax);
        formDataToSend.append('Company_Address_Country', city);
        formDataToSend.append('Company_Address', city);
        formDataToSend.append('Company_PostalCode', code);
        formDataToSend.append('CompanyCity', addressCountry);        formDataToSend.append('Flow_Of_Funds', flowOfFunds);
        formDataToSend.append('Depositor_Industry', depositorIndustry);
        formDataToSend.append('Beneficiary_Industry', beneficiaryIndustry);
        formDataToSend.append('Required_Currency', requiredCurrency);
        formDataToSend.append('Source_Country', sourceCountry);
        formDataToSend.append('Destination_Country', destinationCountry);
        formDataToSend.append('Monthly_Transactions', monthlyTransactions);
        formDataToSend.append('Annual_Volume', annualVolume);
        formDataToSend.append('FX', fx);
        formDataToSend.append('Director_Full_Name', directorName);
        formDataToSend.append('Director_DOB', directorDob);
        formDataToSend.append('Director_Country', directorCountry);
        formDataToSend.append('Director_Full_Name_2', "N/A");
        formDataToSend.append('Director_DOB_2', "N/A");
        formDataToSend.append('Director_Country_2', "N/A");
        formDataToSend.append('Director_Full_Name_3', "N/A");
        formDataToSend.append('Director_DOB_3', "N/A");
        formDataToSend.append('Director_Country_3', "N/A");
        formDataToSend.append('Director_Full_Name_4', "N/A");
        formDataToSend.append('Director_DOB_4', "N/A");
        formDataToSend.append('Director_Country_4', "N/A");
        formDataToSend.append('UBO_Name', name || "N/A");
        formDataToSend.append('UBO_Dob', dob || "N/A");
        formDataToSend.append('UBO_Country', country);
        formDataToSend.append('UBO_Ownership_percentage', ownershipPercentage || "N/A");
        formDataToSend.append('UBO_Name_1', findBeneficiary[0]?.name || "N/A");
        formDataToSend.append('UBO_Dob_1', findBeneficiary[0]?.dob || "N/A");
        formDataToSend.append('UBO.Country_1', findBeneficiary[0]?.country || "N/A");
        formDataToSend.append('UBO_Ownership_percentage_1',findBeneficiary[0]?.ownershipPercentage || "N/A");
        formDataToSend.append('UBO_Name_2', findBeneficiary[1]?.name || "N/A");
        formDataToSend.append('UBO_Dob_2', findBeneficiary[1]?.dob || "N/A");
        formDataToSend.append('UBO_Country_2', findBeneficiary[1]?.country || "N/A");
        formDataToSend.append('UBO_Ownership_percentage_2',findBeneficiary[1]?.ownershipPercentage || "N/A");
        formDataToSend.append('Averse_Media', adverseMedia);
        formDataToSend.append('AML_Policy', customerDueDiligence);
        const response = await axios.post(
            process.env.REACT_APP_GOOGLE_SHEET_SCRIPT_KEY,
          formDataToSend,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
  
        return response.data;
      } catch (error) {
        throw new Error(error.message);
      }
    }
  );


export const walletsSlice = createSlice({
  name: "wallets",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(allWallets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(allWallets.fulfilled, (state, action) => {
        state.status = "idle";

        state.wallets = action.payload;
      })
      .addCase(createWallet.fulfilled, (state, action) => {
        state.status = "idle";
        state.wallets.push(action.payload);
      })
      .addCase(allVirtualAccounts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        allVirtualAccounts.fulfilled,
        (state, action) => {
          state.status = "idle";

          state.virtualAccounts = action.payload;
        },
      )

      .addCase(payoutsInfo.fulfilled, (state, action) => {
        state.status = "idle";
        state.payouts = action.payload;
      })
      .addCase(walletTransactions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        walletTransactions.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.transactions = action.payload.data;
        },
      )
      .addCase(
        walletTransactions.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        },
      )

      .addCase(requestAccountDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        requestAccountDetails.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.requestAccount = action.payload.data;
        },
      )
      .addCase(
        requestAccountDetails.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        },
      ).addCase(CreateUSDAccount.pending, (state) => {
        state.loading = true
      })
      .addCase(
        CreateUSDAccount.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        CreateUSDAccount.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
      ).addCase(GetVirtualAccountStatus.pending, (state) => {
        state.loading = true
      })
      .addCase(
        GetVirtualAccountStatus.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        GetVirtualAccountStatus.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
      ).addCase(sendToGoogleSheet.pending, (state) => {
        state.loading = true
      })
      .addCase(
        sendToGoogleSheet.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        sendToGoogleSheet.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
      ).addCase(MerchantForeignBankFundingRequest.pending, (state) => {
        state.loading = true
      })
      .addCase(
        MerchantForeignBankFundingRequest.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        MerchantForeignBankFundingRequest.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
      ).addCase(getBassFee.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getBassFee.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        getBassFee.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
      ).addCase(getReceivingCountries.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getReceivingCountries.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        getReceivingCountries.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
      ).addCase(getReceivingCurrencyByCountry.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getReceivingCurrencyByCountry.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        getReceivingCurrencyByCountry.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
      ).addCase(getExchangeRate.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getExchangeRate.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        getExchangeRate.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
      ).addCase(addBeneficiary.pending, (state) => {
        state.loading = true
      })
      .addCase(
        addBeneficiary.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        addBeneficiary.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
      ).addCase(getBeneficiaries.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getBeneficiaries.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        getBeneficiaries.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
      ).addCase(sendMerchantOTP.pending, (state) => {
        state.loading = true
      })
      .addCase(
        sendMerchantOTP.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        sendMerchantOTP.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
    ).addCase(sendMerchantPayment.pending, (state) => {
        state.loading = true
      })
      .addCase(
        sendMerchantPayment.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        sendMerchantPayment.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
    )
    .addCase(sendUploadedBASSDocument.pending, (state) => {
        state.loading = true
      })
      .addCase(
        sendUploadedBASSDocument.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.loading = false
        },
      )
      .addCase(
        sendUploadedBASSDocument.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error.message;
        },
    )
  },
});

export default walletsSlice.reducer;
