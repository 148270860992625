import styled from "styled-components";
import { Steps } from "antd";
const { Step } = Steps;

export const StepWrapper = styled(Step)`
  font-family: "Inter";
  padding-left: 0 !important;

  &::after,
  & > div[class="ant-steps-item-container"] {
    //display: none !important;
  }

  &.ant-steps-item {
    margin-right: 12px;
  }

  &.ant-steps-item.ant-steps-item-active::before {
    background-color: #000000;
    height: 3px;
  }

  &.ant-steps-item:not(ant-steps-item-active)::before {
    background: #d9d9d9;
    width: 100%;
    height: 3px;
    left: 0;
  }

  &[class~="ant-steps-item-finish"]
    div[class="ant-steps-item-container"]
    div[class~="ant-steps-item-icon"] {
    background-color: #000000;
    border-color: #000000;
  }

  &[class~="ant-steps-item-process"]
    > div[class="ant-steps-item-container"]
    > div[class~="ant-steps-item-icon"] {
    background-color: #000000;
    border-color: #000000;
  }

  &[class~="ant-steps-item-wait"]
    > div[class="ant-steps-item-container"]
    > div[class~="ant-steps-item-icon"] {
    background-color: #717070;
    opacity: 0.6;
    border-color: #717070;
  }

  &[class~="ant-steps-item-wait"]
    > div[class="ant-steps-item-container"]
    > div[class~="ant-steps-item-icon"]
    > span[class~="ant-steps-icon"] {
    color: #000000;
  }

  div[class="ant-steps-item-container"]
    > div[class~="ant-steps-item-content"]
    > div[class~="ant-steps-item-title"] {
    padding-right: 0;
  }

  div[class="ant-steps-item-container"]
    > div[class~="ant-steps-item-content"]
    > div[class~="ant-steps-item-title"]:after {
    background-color: transparent;
    top: 13.5px;
    margin-left: -8px;
  }

  &[class~="ant-steps-item-finish"]
    div[class="ant-steps-item-container"]
    > div[class~="ant-steps-item-content"]
    > div[class~="ant-steps-item-title"]:after {
    background-color: #000000;
  }

  &
    span[class="ant-steps-icon"]
    span[class~="ant-steps-finish-icon"]
    > svg
    > path {
    fill: #ffffff;
    display: none;
  }

  & div[class="ant-steps-item-container"] > div[class~="ant-steps-item-icon"] {
    width: 27px;
    height: 27px;
    line-height: 27px;
    border-radius: 27px;
    font-size: 14px;
  }

  @media (min-width: 768px) {
    &
      div[class="ant-steps-item-container"]
      > div[class~="ant-steps-item-icon"] {
      width: 53px;
      height: 53px;
      line-height: 53px;
      border-radius: 53px;
      font-size: 20px;
    }

    div[class="ant-steps-item-container"]
      > div[class~="ant-steps-item-content"]
      > div[class~="ant-steps-item-title"]:after {
      top: 26.5px;
    }
  }
`;
