import {
    Collections,
    Dashboard,
    Login,
    Signup,
    CollectionTransaction,
    Customers,
    CustomerDetail,
    Wallets,
    WalletDetails,
    Cards,
    WithdrawFunds,
    SendFunds,
    Transactions,
    Refunds,
    ExchangeRate,
    Installation,
    PaymentLinks,
    VirtualAccount,
    Settlement,
    KlashaWire,
    ContactUs,
    SettlementPayout,
    FundUSDSettlement,
    BASS,
    Withdrawal,
    BassFunding,
    CreateRole,
    PermissionList,
    SetTeamPassword,
    Settings, ResetPassword, Complaince, ReUploadDocuments, ManageBeneficiaries,
    Marketing, Unsubscribe,
    AccountConfirmed, ForgotPassword,
  SendWire,
  SuccessOrange, FailedOrange
} from "./index";
import { appRoutePaths } from "./utils";



export const routeConfig = [
    {
        path: appRoutePaths.login,
        element: <Login />,
        protected: false,
    },
    {
        path: appRoutePaths.createAccount,
        element: <Signup />,
        protected: false,
    },
    {
        path: appRoutePaths.forgotPassword,
        element: <ForgotPassword />,
        protected: false,
    },
    {
        path: appRoutePaths.resetPassword,
        element: <ResetPassword />,
        protected: false,
    },
    {
        path: appRoutePaths.registrationConfirmed,
        element: <AccountConfirmed />,
        protected: false,
    },
    {
        path: appRoutePaths.root,
        element: <Dashboard />,
        protected: true,
    },
    {
        path: appRoutePaths.dashboard,
        element: <Dashboard />,
        protected: true,
    },
    {
        path: appRoutePaths.collections,
        element: <Collections />,
        protected: true,
    },
    {
        path: appRoutePaths.collectionTransactions,
        element: <CollectionTransaction/>,
        protected: true,
    },
    {
        path: appRoutePaths.customers,
        element: <Customers/>,
        protected: true,
    },
    {
        path: appRoutePaths.customerDetail,
        element: <CustomerDetail/>,
        protected: true,
    },
    {
        path: appRoutePaths.wallets,
        element: <Wallets/>,
        protected: true,
    },
    {
        path: appRoutePaths.walletDetail,
        element: <WalletDetails/>,
        protected: true,
    },
    {
        path: appRoutePaths.cards,
        element: <Cards/>,
        protected: true,
    },
    {
        path: appRoutePaths.withdrawFundsFromCard,
        element: <WithdrawFunds/>,
        protected: true,
    },
    {
        path: appRoutePaths.fundCard,
        element: <SendFunds/>,
        protected: true,
    },
    {
        path: appRoutePaths.transactions,
        element: <Transactions/>,
        protected: true,
    },
    {
        path: appRoutePaths.refunds,
        element: <Refunds/>,
        protected: true,
    },
    {
        path: appRoutePaths.rates,
        element: <ExchangeRate/>,
        protected: true,
    },
    {
        path: appRoutePaths.installation,
        element: <Installation/>,
        protected: true,
    },
    {
        path: appRoutePaths.paymentLinks,
        element: <PaymentLinks/>,
        protected: true,
    },
    {
        path: appRoutePaths.virtualAccount,
        element: <VirtualAccount/>,
        protected: true,
    },
    {
        path: appRoutePaths.settlements,
        element: <Settlement/>,
        protected: true,
    },
    {
        path: appRoutePaths.settlementPayout,
        element: <SettlementPayout/>,
        protected: true,
    },
    {
        path: appRoutePaths.fundUSDSettlement,
        element: <FundUSDSettlement/>,
        protected: true,
    },
    {
        path: appRoutePaths.klashaWire,
        element: <KlashaWire/>,
        protected: true,
    },
    {
        path: appRoutePaths.contactUs,
        element: <ContactUs/>,
        protected: true,
    },
    {
        path: appRoutePaths.bassAccount,
        element: <BASS/>,
        protected: true,
    },
    {
        path: appRoutePaths.bassWithdrawal,
        element: <Withdrawal/>,
        protected: true,
    },
    {
        path: appRoutePaths.bassFunding,
        element: <BassFunding/>,
        protected: true,
    },
    {
        path: appRoutePaths.bassFunding,
        element: <BassFunding/>,
        protected: true,
    },
    {
        path: appRoutePaths.createTeamRole,
        element: <CreateRole/>,
        protected: true,
    },
    {
        path: appRoutePaths.viewTeamPermision,
        element: <PermissionList/>,
        protected: true,
    },
    {
        path: appRoutePaths.setTeamPassword,
        element: <SetTeamPassword/>,
        protected: true,
    },
    {
        path: appRoutePaths.settings,
        element: <Settings/>,
        protected: true,
    },
    {
        path: appRoutePaths.settingsTab,
        element: <Settings/>,
        protected: true,
    },
    {
        path: appRoutePaths.compliance,
        element: <Complaince/>,
        protected: true,
    },
    {
        path: appRoutePaths.reUploadDocuments,
        element: <ReUploadDocuments/>,
        protected: true,
    },
    {
        path: appRoutePaths.manageBeneficiary,
        element: <ManageBeneficiaries/>,
        protected: true,
    },
    {
        path: appRoutePaths.marketing,
        element: <Marketing/>,
        protected: true,
    },
    {
        path: appRoutePaths.unsubScribe,
        element: <Unsubscribe/>,
        protected: true,
    },
    {
        path: appRoutePaths.sendWire,
        element: <SendWire/>,
        protected: true,
    },
    {
        path: appRoutePaths.successOrange,
        element: <SuccessOrange/>,
        protected: false,
    },
    {
        path: appRoutePaths.failedOrange,
        element: <FailedOrange/>,
        protected: false,
    }
];