import React from "react";
import {
  Wrapper,
  Top,
  Bottom,
  BottomItem,
  BottomLabel,
  BottomValue,
} from "./style";
import TransactionStatus from "../../transactions/components/TransctionStatus";
import { formatLongString } from "../../../utils/formatString";

interface CardProps {
  amount: string;
  payoutId: string;
  accBank: string;
  accNo: string;
  accName: string;
  status: string;
  reqDate: string;
}

const BalanceListCard = ({
  amount,
  payoutId,
  accNo,
  accBank,
  accName,
  status,
  reqDate,
}: CardProps) => {
  return (
    <Wrapper title="balance-card">
        <Top>
          <p>Amount</p>
          <span>{amount}</span>
        </Top>

      <Bottom>
      <BottomItem>
          <BottomLabel>Payout ID</BottomLabel>
          <BottomValue>{formatLongString(payoutId, 15)}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Bank</BottomLabel>
          <BottomValue>{accBank}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Account name</BottomLabel>
          <BottomValue>{accName}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Account number</BottomLabel>
          <BottomValue>{accNo}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Request date</BottomLabel>
          <BottomValue>{reqDate}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Status</BottomLabel>
          <TransactionStatus text={status}/>
        </BottomItem>
      </Bottom>
    </Wrapper>
  );
};

export default BalanceListCard;
