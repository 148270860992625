import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../components/common/Button";
import dayjs from "dayjs";
import {
    ButtonsDiv,
    Title,
    CurrencyBalance,
    AddFundsHeader,
    Icon,
    Modal,
    ErrorWrapper,
    SendPaymentWrapper,
    Heading,
    BassWrapper,
} from "./style";
import CurrencySelector from "./components/CurrencySelector/CurrencySelector";
import ErrorMessage from "../../../../components/ErrorMessage";
import CustomModal from "../../../../components/common/CustomModal";
import CurrencyFlag from "../../components/CurrencyFlag";
import ReceiveFunds from "./components/ReceiveFunds";
import SendFunds from "./components/SendFunds/index";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../app/hooks";
import {
    ALLOWED_CURRENCIES,
    formatBool,
} from "../../utils";
import { formatCurrencyCodeAmount } from "../../../../utils/currency";

import ReceiveWithNonNGN from "./components/ReceiveFunds/ReceiveWithNonNGN";
import TransactionStatus from "../../../../components/transactions/components/TransctionStatus";
import CustomTable from "../../../../components/common/CustomTable";
import {
    EmptyWrapper,
    EmptyWrapperSubTitle,
    EmptyWrapperTitle,
} from "../../../Balances/style";
import {
    addFunds,
    getAllRequests,
    updateRequest,
} from "../../api";
import Loader from "../../../../components/common/Loader";
import KlashaAccount from "./components/ReceiveFunds/KlashaAccount";
import { formatLongString } from "../../../../utils/formatString";
import Arrow from "../../../../assets/icons/arrow-up.svg";
import Copied from "../../../../assets/icons/copy.svg";
import Table from "./components/Table/Table";
import Menu from "./components/Menu/Menu";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import { selectUserRoleAndPermissions } from "../../../Settings/Team/redux/slice";
import { GetVirtualAccountStatus } from "../../redux/slice";
import AccountWrapper from "./components/AccountInfo/AccountWrapper";
import { ReactComponent as HomeAccountIcon } from "../../assets/home-account-icon.svg";
import BASSAccount from "../../components/BASSAccount/BASSAccount";
import { FundingContent } from "../../components/FundingContent";
import { encryptAlgorithm } from "../../../../utils/encryptionAlgorithm";

type EXPECTED_PARAMS = {
    currencyCode: ALLOWED_CURRENCIES;
};

const MINIMUM_USD =
    process.env.REACT_APP_ENV === "dev" ? 1 : 100;

const items = [
    {
        key: "1",
        label: "Update payment",
        icon: Arrow,
    },
    {
        key: "2",
        label: "Copy reference",
        icon: Copied,
    },
];

const EmptyBalanceDescription = () => {
    return (
        <EmptyWrapper>
            <EmptyWrapperTitle>
                No transactions yet
            </EmptyWrapperTitle>
            <EmptyWrapperSubTitle>
                All received or sent transactions will
                appear here
            </EmptyWrapperSubTitle>
        </EmptyWrapper>
    );
};

const SingleWallet = () => {
    const { currencyCode } = useParams<EXPECTED_PARAMS>();
    const { wallets, virtualAccounts } = useAppSelector(
        (state) => state?.wallets,
    );
    const findVirtualAccount = virtualAccounts.find(
        (data) => data.currency === "USD",
    );

    const successAlertContext = useContext(
        SuccessAlertContext,
    );

    const navigate = useNavigate();
    const { onShowAlert: onSuccess } =
        successAlertContext || {};
    const { onShowAlert } = useContext(
        NetworkErrorAlertContext,
    );
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const { business } = useAppSelector(
        (state) => state.settings,
    );
    const [minimumRecipientAmount] = useState(MINIMUM_USD);
    const [state, setState] = useState({
        status: "pending" as
            | "pending"
            | "completed"
            | "failed"
            | "not-found",
        loading: false,
        virtualAccount: null,
        modalVisible: null,
        accountModal: null,
    });

    const [showMaximumError, setShowMaximumError] =
        useState(false);
    const [addFundsAmount, setAddFundsAmount] =
        useState<any>();

    const findWalletId = wallets?.find(
        (wallets) => wallets?.currency === currencyCode,
    );
    const { id } = findWalletId ?? { id: null };

    const { role, permissions } = useAppSelector(
        selectUserRoleAndPermissions,
    );
    const dispatch = useAppDispatch();

    const [showReceiveModal, setShowReceiveModal] =
        useState(false);
    const [showSendModal, setShowSendModal] =
        useState(false);
    const [updatePayment, setUpdatePayment] =
        useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [currentWallet, setCurrentWallet] =
        useState(null);
    const [requestModal, setRequestModal] = useState(false);
    const [totalItem, setTotalItem] = useState<any>([]);
    const [showKlashaAccount, setShowKlashaAccount] =
        useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [narration, setNarration] = useState("");
    const [tableRef, setTableRef] = useState(null);
    const [amount, setAmount] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [virtualAccount, setVirtualAccount] =
        useState(null);
        const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );    

    const hasPayoutPermission = permissions?.[
        "wallet"
    ]?.includes("CREATE_WALLET_PAYOUT");
    const hasTopUpPermission = permissions?.[
        "wallet"
    ]?.includes("CREATE_ACCOUNT_VA");

    const tableColumns = [
        {
            title: "Reference",
            dataIndex: "ref",
            key: "ref",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Payment status",
            dataIndex: "payment_status",
            key: "payment_status",
            render: (text: string) => (
                <TransactionStatus text={text} />
            ),
        },

        {
            title: "Credit status",
            dataIndex: "credit_status",
            key: "credit_status",
            render: (text: string) => (
                <TransactionStatus text={text} />
            ),
        },
        {
            title: "",
            dataIndex: "operation",
            key: "operation",
            render: () => (
                <Menu
                    items={items}
                    handleItemClick={handleItemClick}
                    tableData={tableRef}
                />
            ),
        },
    ];

    const handleItemClick = (key) => {
        if (key === "1") {
            setRequestModal(false);
            setUpdatePayment(true);
        } else if (key === "2") {
            onCopyLinkToClipboard(tableRef?.narration);
        }
    };

    const onCopyLinkToClipboard = (data) => {
        navigator.clipboard.writeText(data);
    };

    const handleDataUpdate = (field, value) => {
        setState((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const getBassVirtualAccountStatus = async () => {
        handleDataUpdate("loading", true);

        try {
            const data = await dispatch(
                GetVirtualAccountStatus({ businessId }),
            );

            if (
                GetVirtualAccountStatus.fulfilled.match(
                    data,
                )
            ) {
                const { status } = data.payload.data;
                handleStatusUpdate(status);
            }

            if (
                GetVirtualAccountStatus.rejected.match(data)
            ) {
                handleDataUpdate("failed", true);
            }
        } catch (error) {
            console.error(
                "Error fetching virtual account status:",
                error,
            );
        } finally {
            handleDataUpdate("loading", false);
        }
    };

    const handleStatusUpdate = (status) => {
        const statusMap = {
            PROCESSED: "completed",
            NOTFOUND: "failed",
            PENDING: "pending",
        };

        const newStatus = statusMap[status];
        handleDataUpdate("status", newStatus);
    };

    useEffect(() => {
        if (businessId && currencyCode === "USD") {
            getBassVirtualAccountStatus();
        }
    }, []);

    const onPageChange = (page: number) => {
        setPageNumber(page);
    };

    const handlegetAllRequests = () => {
        const data = {
            walletId: id,
            pageNumber: pageNumber,
            pageSize: 5,
        };
        getAllRequests(data)
            .then((response) => {
                setTotalItem(response?.data);

                const tableData =
                    response?.data?.fundingBusinessWalletRequests.map(
                        (item) => ({
                            key: item.id,
                            ref: formatLongString(
                                item.reference,
                                10,
                            ),
                            amount: formatCurrencyCodeAmount(
                                item.currency,
                                item.fundAmount,
                            ),
                            narration: item.reference,
                            date: dayjs(
                                item.createdAt,
                            ).format("DD.MM.YYYY - HH:mm"),
                            payment_status: formatBool(
                                item.paid,
                            ),
                            credit_status:
                                item.creditStatus,

                            narrationAmount:
                                item.fundAmount,
                        }),
                    );
                setTableData(tableData);
            })
            .catch((error) => {
                return error;
            });
    };

    useEffect(() => {
        handlegetAllRequests();
    }, [requestModal, pageNumber]);

    const handleReceiveFunds = async () => {
        setLoading(true);
        try {
            const data = {
                email: business?.email,
                businessName: business?.name,
                currency: currencyCode,
                fundAmount: amount,
                walletId: id,
                businessId: businessId,
            };

            const encryptedData = encryptAlgorithm(data);
            const payload = {
                message: encryptedData,
            };

            await addFunds(payload).then((data) => {
                setNarration(data?.data?.reference);

                setLoading(false);
                setShowKlashaAccount(true);
            });
        } catch (error) {
            onShowAlert("", error?.response?.data.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (
            addFundsAmount &&
            addFundsAmount < minimumRecipientAmount
        ) {
            setShowMaximumError(true);
        } else {
            setShowMaximumError(false);
        }
    }, [addFundsAmount]);

    const handleCloseRequests = () => {
        setRequestModal(!requestModal);
    };

    const handleOpenRequests = () => {
        setRequestModal(true);
    };

    const handleCloseModal = () => {
        setShowReceiveModal(false);
        setShowKlashaAccount(false);
    };

    const handleUpdatePayment = async () => {
        setLoading(true);
        try {
            await updateRequest(
                narration || tableRef?.narration,
            );
            setLoading(false);
            setShowReceiveModal(false);
            handlegetAllRequests();
            onSuccess("Request successfully submitted");
            setShowKlashaAccount(false);
            setAmount(null);
            setUpdatePayment(false);
        } catch (error) {
            onShowAlert("", error?.response?.data.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        setCurrentWallet(
            wallets?.find(
                (wallet) =>
                    wallet.currency === currencyCode,
            ),
        );
        setVirtualAccount(
            virtualAccounts?.find(
                (acc) => acc.currency === currencyCode,
            ),
        );
    }, [wallets]);

    const handleRowClick = (row: any) => {
        setTableRef(row);
    };

    const handleTopUp = () => {
        if (hasTopUpPermission || role === "OWNER") {
            if (businessId && business?.approved) {
                setShowReceiveModal(true);
            } else {
                onShowErrorAlert(
                    "",
                    "Business awaiting approval.",
                );
            }
        } else {
            onShowErrorAlert("", "Access is denied");
        }
    };
    const handleSend = () => {
        if (!hasPayoutPermission && role !== "OWNER") {
            onShowErrorAlert("", "Access is denied");
            return;
        }

        if (!businessId || !business?.approved) {
            onShowErrorAlert(
                "",
                "Business awaiting approval",
            );
            return;
        }

        setShowSendModal(true);

        if (currencyCode === "USD") {
            navigate("/wallets/withdrawal/bass");
        }
    };

    const handleBankTransfer = () => {
        setShowReceiveModal(true)
        handleDataUpdate(
            "accountModal",
            false,
        )
    }

    if (currentWallet) {
        return (
            <>
                <Title>
                    <CurrencyFlag
                        currency={currentWallet?.currency}
                    />{" "}
                    {`${currencyCode}  balance`}
                </Title>
                <CurrencyBalance>
                    <span />
                    {currencyCode + "" + " "}
                    {currentWallet.availableBalance !== 0
                        ? formatCurrencyCodeAmount(
                              "",
                              currentWallet?.availableBalance?.toFixed(
                                  2,
                              ),
                          )
                        : "0.00"}
                </CurrencyBalance>

                <ButtonsDiv>
                    {currencyCode !== "USD" && currencyCode !== "KES" && (
                        <Button
                            label="Top up"
                            type="submit"
                            theme="secondary"
                            height="40px"
                            fontSize="12px"
                            onClick={handleTopUp}
                        />
                    )}

                    {currencyCode === "USD" && findVirtualAccount && 
                     (
                            <Button
                                theme="secondary"
                                fontSize="12px"
                                height="40px"
                                className="account-fund-btn"
                                onClick={() =>
                                    handleDataUpdate(
                                        "modalVisible",
                                        true,
                                    )
                                }
                                disabled={
                                    state.status !==
                                    "completed"
                                }
                            >
                                <div className="flex">
                                    <HomeAccountIcon />
                                    Account details
                                </div>
                            </Button>
                        )}

                        {(currencyCode === "USD"  || currencyCode === "KES") &&
                         (
                            <Button
                                theme="secondary"
                                height="40px"
                                label="Fund"
                                fontSize="12px"
                                onClick={() =>
                                    handleDataUpdate(
                                        "accountModal",
                                        true,
                                    )
                                }
                                disabled={
                                    state.loading
                                
                                }
                            />
                        )}

                    {currencyCode !== "NGN" &&
                        currencyCode !== "USD" && (
                            <Button
                                label="All requests"
                                fontSize="12px"
                                height="40px"
                                theme="alternate"
                                onClick={handleOpenRequests}
                            />
                        )}
                    
                    <Button
                        label="Send"
                        type="submit"
                        theme="alternate"
                        height="40px"
                        fontSize="12px"
                        onClick={handleSend}
                    />
                </ButtonsDiv>
                {currencyCode === "USD" &&
                 (
                        <BassWrapper>
                            {!state.loading && (
                                <AccountWrapper
                                    status={state.status}
                                    handleClick={() =>
                                        navigate(
                                            "account-creation/company",
                                        )
                                    }
                                />
                            )}
                        </BassWrapper>
                    )}
                <Table />

                <CustomModal
                    isModalVisible={showReceiveModal}
                    onClose={handleCloseModal}
                    width="480px"
                >
                    {currencyCode === "NGN" && (
                        <ReceiveFunds
                            onDone={() =>
                                setShowReceiveModal(false)
                            }
                            account={virtualAccount}
                            currency={currencyCode}
                        />
                    )}

                    {currencyCode !== "NGN" &&
                        !showKlashaAccount && (
                            <ReceiveWithNonNGN
                                onDone={handleReceiveFunds}
                                value={amount}
                                onValueChange={setAmount}
                            />
                        )}

                    {showKlashaAccount && (
                        <KlashaAccount
                            title={`Transfer ${currencyCode} ${amount} to Klasha`}
                            narration={formatLongString(
                                narration,
                                15,
                            )}
                            narrationDetail={narration}
                            callBack={handleUpdatePayment}
                        />
                    )}
                </CustomModal>

                {showAddModal && (
                    <CustomModal
                        isModalVisible={showAddModal}
                        onClose={() =>
                            setShowAddModal(false)
                        }
                        width="450px"
                    >
                        <AddFundsHeader>
                            Add funds to your wallet
                        </AddFundsHeader>

                        <SendPaymentWrapper>
                            <CurrencySelector
                                countries={[
                                    {
                                        currencyCode:
                                            currencyCode,
                                        flagCode:
                                            currencyCode,
                                    },
                                ]}
                                placeholder="Add funds you'd like to send"
                                value={addFundsAmount}
                                onValueChange={(val) =>
                                    setAddFundsAmount(val)
                                }
                                defaultValue={currencyCode}
                                selectValue={currencyCode}
                                arrow={true}
                                selector={true}
                            />

                            <ErrorWrapper>
                                {showMaximumError && (
                                    <ErrorMessage
                                        message={`Minimum amount: ${currencyCode} ${new Intl.NumberFormat().format(
                                            minimumRecipientAmount,
                                        )}`}
                                    />
                                )}
                            </ErrorWrapper>
                        </SendPaymentWrapper>
                    </CustomModal>
                )}

                {showSendModal && (
                    <Modal
                        style={{
                            position: "fixed",
                            top: 10,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: 50,
                            height: "100%",
                            backgroundColor: "#FFFFFF",
                            paddingTop: "50px",
                            overflow: "scroll",
                        }}
                    >
                        <Icon
                            onClick={() =>
                                setShowSendModal(false)
                            }
                        />
                        <SendFunds
                            close={showSendModal}
                            setClose={setShowSendModal}
                        />
                    </Modal>
                )}

                <CustomModal
                    isModalVisible={requestModal}
                    onClose={handleCloseRequests}
                    width="780px"
                >
                    <Heading>All deposit requests</Heading>

                    <CustomTable
                        columns={tableColumns}
                        extraEmptyChildren={
                            <EmptyBalanceDescription />
                        }
                        dataSource={tableData}
                        currentPage={totalItem?.pageNumber}
                        totalItemCount={
                            totalItem?.totalCount
                        }
                        onPageChange={onPageChange}
                        handleRowClick={handleRowClick}
                    />
                </CustomModal>

                <CustomModal
                    isModalVisible={updatePayment}
                    onClose={() => setUpdatePayment(false)}
                    width="480px"
                >
                    <KlashaAccount
                        title={`Transfer ${currencyCode} ${tableRef?.narrationAmount} to Klasha`}
                        narration={formatLongString(
                            tableRef?.narration,
                            15,
                        )}
                        narrationDetail={narration}
                        callBack={handleUpdatePayment}
                    />
                </CustomModal>

                <CustomModal
                    isModalVisible={state.modalVisible}
                    width="480px"
                    onClose={() =>
                        handleDataUpdate(
                            "modalVisible",
                            false,
                        )
                    }
                >
                    <BASSAccount
                        accountName={
                            findVirtualAccount?.accountName ||
                            "N/A"
                        }
                        accountBIC={
                            findVirtualAccount?.accountBIC ||
                            "N/A"
                        }
                        accountIban={
                            findVirtualAccount?.iban ||
                            "N/A"
                        }
                        intermediaryBIC={
                            findVirtualAccount?.bankName ||
                            "N/A"
                        }
                        intermediaryBank={
                            findVirtualAccount?.intermediateName ||
                            "N/A"
                        }
                    />
                </CustomModal>

                <CustomModal
                    isModalVisible={state.accountModal}
                    width="480px"
                    onClose={() =>
                        handleDataUpdate(
                            "accountModal",
                            false,
                        )
                    }
                >
                    <FundingContent
                        accountName={
                            findVirtualAccount?.accountName ||
                            "N/A"
                        }
                        accountBIC={
                            findVirtualAccount?.accountBIC ||
                            "N/A"
                        }
                        accountIban={
                            findVirtualAccount?.iban ||
                            "N/A"
                        }
                        intermediaryBIC={
                            findVirtualAccount?.bankName ||
                            "N/A"
                        }
                        intermediaryBank={
                            findVirtualAccount?.intermediaryBic                            ||
                            "N/A"
                        }
                        navigate={() =>
                            navigate(
                                `/wallets/${currencyCode}/fund-account`,
                            )
                        }
                        handleAccountTransfer={handleBankTransfer}
                    />
                </CustomModal>

                <Loader
                    isLoading={loading || state.loading}
                />
            </>
        );
    } else return <input type="hidden" />;
};

export default SingleWallet;
