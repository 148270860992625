import React, { ReactNode } from "react";
import { Wrapper } from "./style";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

interface CustomModalProps {
  isModalVisible: boolean;
  onClose: () => void;
  width?: string;
  height?:string;
  children?: ReactNode;
}

const CustomModal = ({
  isModalVisible,
  onClose,
  width,
  children,
}: CustomModalProps) => {
  return (
    <Wrapper
      closeIcon={<CloseIcon />}
      onCancel={onClose}
      open={isModalVisible}
      footer={null}
      $width={width}
      wrapClassName="custom-modal"
    >
      {children}
    </Wrapper>
  );
};

export default CustomModal;
