import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StepOne from "../../components/compliance/Steps/components/StepOne";
import {
    Container,
    Content,
    StepsWrapper,
    Wrapper,
    BackButton,
    StepHeader,
    StepTitle,
    NavButton,
    MainStep,
} from "./style";
import StepTwo from "../../components/compliance/Steps/components/StepTwo";
import StepThree from "../../components/compliance/Steps/components/StepThree";
import CustomStep from "../../components/common/CustomStep";
import StepFour from "../../components/compliance/Steps/components/StepFour";
import { useAppSelector } from "../../app/hooks";
import { ReactComponent as BackIcon } from "../../assets/icons/arrow-right.svg";

const stepStringToNum = {
    STEP1: 0,
    STEP2: 1,
    STEP3: 2,
    STEP5: 2,
    STEP6: 2,
    STEP4: 3,
};

const StepShown = ({
    stepNumber,
    updateStep,
    orgType,
    businessType,
}: {
    stepNumber: number;
    updateStep: (step: number) => void;
    orgType?: (data: string) => void;
    businessType?: (data: string) => void;
}) => {
    const onNextStep = () => {
        if (stepNumber !== 6) {
            updateStep(stepNumber + 1);
        }
    };

    switch (stepNumber) {
        case 0:
            return <StepOne onSave={onNextStep} setSelectedBusinessType={orgType} />;
        case 1:
            return <StepTwo onSave={onNextStep} businessType={businessType} />;
        case 2:
            return <StepThree onSave={onNextStep} />;
        case 3:
            return <StepFour  />;
        default:
            return null;
    }
};

const Compliance = () => {
    const [current, setCurrent] = useState(0);
    const [orgType, setOrgType] = useState<any>();
    const [stepsToText, setStepsToText] = useState({
        0: "Tell us about your business",
        1: ` Add business representative`,
        2: "Additional business details",
        3: "Upload your business document",
    });
    const navigate = useNavigate();

    const { complianceData } = useAppSelector((state) => state.compliance);
   
    useEffect(() => {
        if (
            complianceData &&
            complianceData?.startupComplianceStatus &&
            !location.pathname.includes("klasha-business")
        ) {
             if (
                ["DONE", "STEP7"].includes(
                    complianceData?.startupComplianceStatus,
                )
            ) {
                navigate("/dashboard");
            }
            const initialStepString =
                complianceData.startupComplianceStatus;
            const initialStepNum =
                stepStringToNum[initialStepString];
            setCurrent(initialStepNum);
        }

        if (orgType) {
            setStepsToText((prevSteps) => ({
                ...prevSteps,
                1: `${
                    orgType === "Starter Business"
                        ? "Add business owner's information"
                        : "Add director's information"
                }`,
            }));
        }
    }, [
        navigate
    ]);

    const onBackButtonClick = () => {
        setCurrent(current - 1);
    };



    return (
        <Container>
            <NavButton>
                {current !== 0 && (
                    <BackButton onClick={onBackButtonClick}>
                        <BackIcon />
                    </BackButton>
                )}
            </NavButton>
            <Wrapper>
                <StepsWrapper>
                    <StepHeader>
                        <StepTitle>{stepsToText[current]}</StepTitle>
                    </StepHeader>
                    <MainStep
                        progressDot
                        current={current}
                        responsive={false}
                        style={{ position: "relative", left: "-75px" }}
                    >
                        <CustomStep title="" />
                        <CustomStep title="" />
                        <CustomStep title="" />
                        <CustomStep title="" />
                        <CustomStep title="" />
                    </MainStep>
                </StepsWrapper>
                <Content>
                    <StepShown
                        stepNumber={current}
                        updateStep={setCurrent}
                        orgType={setOrgType}
                        businessType={orgType}
                    />
                </Content>
            </Wrapper>
        </Container>
    );
};

export default Compliance;
