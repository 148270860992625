import React from 'react'
import {
    Wrapper, 
    Step,
    Title,
    Close,
    MobileStep,
    Ball,
    Line,
} from './style';
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

interface Props {
    stages: any[];
    step: number;
    tooltipText?: string;
    setStep?: (arg: number) => void;
    onClose?: () => void;
}

const CustomStepper = (
    {stages, step, setStep, onClose, tooltipText}: Props) => {

    const handleClick = (stg: number) => {
        if(setStep){
            setStep(stg)
        }
    };
    
  return (
    <Wrapper>
        <Step stages={stages}>
            {stages.map((item) => (
            <>
                <Title 
                    key={item.title}
                    stage={item.stage} 
                    step={step}
                    disabled={item.stage > step}
                    onClick={() =>handleClick(item.stage)}
                    >
                    {item.title}
                    {item.toolTip && <span>{tooltipText}</span>}
                </Title>
                <span key={item.stage}/>
            </>
            ))}
        </Step>

        <MobileStep>
            <Ball step={step} stages={stages} />
            <p>
                {stages.map((item) => (
                    <Line key={item.title} stage={item.stage} step={step} stages={stages} />
                ))}
            </p>
        </MobileStep>

        {onClose && (
            <Close onClick={onClose}  data-testid='close-btn'>
            <CloseIcon />
        </Close>
        )}
    </Wrapper>
  )
}

export default CustomStepper