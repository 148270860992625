import React from "react";
import { StepWrapper } from "./style";

interface StepProps {
  title?: string;
}
const CustomStep = ({ title, ...props }: StepProps) => {
  return <StepWrapper title={title ?? ""} {...props} />;
};

export default CustomStep;
