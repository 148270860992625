import styled from "styled-components";
import { Button, Upload } from "antd";

export const Wrapper = styled.form`
  width: 100%;
  font-family: "Proxima Nova", sans-serif;
`;

export const Content = styled.div`
  margin-bottom: 40px;
  width: min(700px, 100%);
`;

export const Section = styled.div`
  margin-bottom: 32px;

  & > p {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    color: #000000;
  }

  & div[class~="ant-picker"] {
    height: 40px !important;
  }
`;

export const SubmitButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & > *:first-child:not(:last-child) {
    margin-right: 16px;
  }
`;

export const ProprietorContainer = styled.div`
  margin-bottom: 32px;
`;

export const ProprietorCards = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  justify-content: space-between;
  & > div {
    width: 100%;
  }
  @media (min-width: 768px) {
    & > div {
      // width: 48%;
    }
  }
`;

export const AddProprietor = styled.button`
  display: flex;
  align-items: center;
  & p {
    margin-bottom: 0;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--primary-pink);
    margin-left: 9px;
  }
`;

export const UploadContainer = styled.div`
  height: 150px;
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  padding: 12px;
`;

export const UploadBusinessContainer = styled.div`
  min-height: 250px;
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  padding: 12px;
`;

export const UploadHeader = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  height: 47px;
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #565c69;
  }

  li {
    color: #818691;
    font-size: 11px;
  }

  .doc-title {
    font-size: 12px;
  }
`;

export const Agreement = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  row-wrap: wrap;
`;

export const UploadWrapper = styled(Upload)`
  width: 100%;
  display: flex;
  border: 1px dashed #0a0a0a;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  gap: 99px;
  height: 62px;
  cursor: pointer;

  & > div {
    height: 100%;
  }

  & div[class~="ant-upload"] {
    width: 100%;
  }
  & div[class~="ant-upload-list"] {
    display: none;
  }
  & button:hover {
    color: black !important;
    border-color: black !important;
  }
`;

export const BusinessUploadWrapper = styled(Upload)`
  width: 100%;
  display: flex;
  border: 1px dashed #0a0a0a;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  gap: 99px;
  height: 62px;
  cursor: pointer;
  margin-top: 116px;

  & > div {
    height: 100%;
  }

  & div[class~="ant-upload"] {
    width: 100%;
  }
  & div[class~="ant-upload-list"] {
    display: none;
  }
  & button:hover {
    color: black !important;
    border-color: black !important;
  }
`;

export const UploadButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;

  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #464646;
  }

  & span {
    text-decoration-line: underline;
    margin-right: 5px;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-left: 6px;
  }
  & div[class~="ant-progress-inner"] {
    background-color: #e9e9e9;
  }
  & div[class~="ant-progress-bg"] {
    background-color: #3a40dd;
    height: 4px !important;
  }
  & div[class~="ant-progress-text"] {
    display: none;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  //border-radius: 8px;
  & > div {
    width: 100%;
  }

  & svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const SubHeader = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #818691;
  display: ${(props: { disabled?: boolean }) =>
    props.disabled ? "none" : "block"};
`;
