import styled from "styled-components";

interface Props {
    currency?: string;
}

export const WalletSummarySection = styled.div`
    display: flex;

    .coming-soon {
        border: 0.25px solid #000000;
        border-radius: 2px;
        color: #000000;
        font-size: 10px;
        cursor: not-allowed;
    }

    .amount {
        text-align: left;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
      }
`;

export const Wrapper = styled.div`
    display: flex;
    gap: 10px;
`;

export const WalletSummaryCard = styled.button<Props>`
    background-color: "#FCFCFC";
    cursor: ${(props) =>
        props.currency === "GBP"
            ? "not-allowed"
            : "pointer"};
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    width: 214px;
    height: 128px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px;
    color: #000000;
    h4 {
        font-family: "Athletics";
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        line-height: 32px;
        letter-spacing: -0.4px;
        margin: 0;
    }

    p {
        margin: 0;
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    &:hover {
        color: #000000;
    }

    @media (max-width: 768px) {
        width: 100%;
      }
`;

export const WalletSummaryCardIcon = styled.span`
    border-radius: 50%;
    background: #e8eaee;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DropDownBoxCurrencyCode = styled.span`
    font-size: 14px;
`;

export const SelectedDropdownOption = styled.div`
    display: flex;
    align-items: center;

    & li {
        margin-right: 10px;
    }
`;

export const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
    margin:0;
    margin-top: 10px;

    button {
        width: 48%;
    }
`;

export const Heading = styled.h1`
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #000000;
    line-height: 32px;
    letter-spacing: -0.48px;
    font-family: "Athletics";
`;

export const Title = styled.p`
    font-family: "Athletics";
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 20px;
`;


export const TermsWrapper = styled.div`
  display: flex;
  align-items: center; /* Vertically align the items in the center */
  gap: 5px;
  margin-top:20px;

  input[type="checkbox"] {
    margin: 0;
    vertical-align: middle;
  }

  p {
    margin-left: 8px;
    margin: 0;
    vertical-align: middle;
    font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 14px;
text-align: left;

  }
  span{
    color: #EF2C5A;
    cursor: pointer;

  }
`;

export const TernsContent = styled.div`
h1{
font-family: Athletics;
font-size: 20px;
font-weight: 700;
line-height: 32px;
letter-spacing: -0.02em;
text-align: center;

}

p, ul{
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
color: #757575;
}

h3{
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
font-weight: 700;
color: #757575;

}
span{
font-family: Inter;
font-size: 16px;
font-weight: 700;
line-height: 24px;
text-align: left;
color: #757575;


}
`