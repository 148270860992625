import React from 'react';
import { Wrapper, Title, Subtitle } from './style';

interface Props {
    title: string;
    subtitle: string;

}

const CollectionBalance = ({ title, subtitle }: Props) => {
    return (
        <Wrapper>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </Wrapper>
    )
}

export default CollectionBalance;