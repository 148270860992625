import styled, { css } from "styled-components";

export const StatusTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: ${(props) => (props.$status === "pending_reversal" ? "131px" : "98px")};
  border-radius: 29px;

  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }


  ${(props: { $status: string }) => {
    if (props.$status === "pending" || props.$status === "pending_reversal") {
      return css`
        background-color: rgba(253, 230, 138, 0.60);
        & span {
          color: #78350F;
        }
      `;
    } else if (props.$status === "successful") {
      return css`
        background-color: rgba(134, 255, 188, 0.60);
        & span {
          color: #004700;
          text-transform: capitalize;
        }
        `;
    }else if (props.$status === "inactive") {
        return css`
        background-color: rgba(208, 208, 208, 0.60);  
             & span {
            color: #000;

            ;
            text-transform: capitalize;
          }
          `;
        }
        else if (props.$status === "in review") {
            return css`
            background-color: rgba(253, 230, 138, 0.60);  
                 & span {
                color: #78350F;
              }
              `;
            }
    
    else if(props.$status === "active"){
        return css`
        background-color: rgba(134, 255, 188, 0.60);
        & span {
          color: #004700;
          text-transform: capitalize;
        }
        `;
    
    } else if (props.$status === "failed") {
      return css`
        background-color: rgba(254, 207, 216, 0.60);
  
        & span {
          text-transform: capitalize;
          color: #D10013;
        }
        `;
    } else if (props.$status === "cancelled") {
      return css`
        background-color: #f1f1f1;

        & span {
          text-transform: capitalize;
          color: #000000;
        }
        `;
    } else if (props.$status === "reversed") {
      return css`
        background-color: rgba(208, 208, 208, 0.60);

        & span {
          text-transform: capitalize;
          color: #000000;
        }
        `;

        
    }
    return css`
      background-color: transparent;
      
      & > span {
        text-transform: capitalize;
        color: #000000;
      }
    `;
  }}
`;

export const TransactionStatusWrapper = styled.div`
  & .tooltip-container.bottom.end,
  & .tooltip-container.bottom.middle {
    max-width: 290px;
    width: 290px;
    color: #fff;

    @media screen and (max-width: 375px) {
      width: fit-content;
      max-width: fit-content;
    }
  }

  & .tooltip-container.bottom.end {
    right: 0px;
  }

  & .tooltip-container.bottom.end::after {
    right: 25px;
  }
`;

export const TransactionStatusToolTip = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  width: 100%;

  & svg {
    margin-top: 2px;
    width: 56px;
  }
`;

export const TransactionStatusContent = styled.div`
  text-align: left;
`;

export const StatusHeading = styled.h3`
  color: #fff;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
`;

export const StatusBody = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #fff;

  a {
    color: #fff;
    text-decoration: underline;
  }
`;