import styled from "styled-components";

export const StyledTransactionsTable = styled.div`
  font-family: "Inter";
  margin-top: 35px;
  overflow: hidden;
  
  .transaction-list {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    margin: 20px;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    line-height: 28px; 
  }
  
  @media (min-width: 768px) {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

`;

export const MobileBody = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;
`;

export const ToggleContainer = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
`;
