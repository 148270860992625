import exportFromJSON from "export-from-json";
import TransactionStatus from "../../components/transactions/components/TransctionStatus";
import { countries } from 'countries-list';
import dayjs from "dayjs";
import { formatCurrencyAmount, formatCurrencyCodeAmount } from "../../utils/currency";

export const CURRENCIES = {
    NGN: { name: "NG Naira", symbol: "&#8358;" },
    USD: { name: "United States Dollar", symbol: "$" },
    ZAR: { name: "South African Rand", symbol: "R" },
    KES: { name: "Kenyan Shillings", symbol: "KSh" },

};

export const COLLECTION_CURRENCIES = {
    KES: { name: "Kenyan Shilling", symbol: "KSh" },
    NGN: { name: "Nigerian Naira", symbol: "₦" },
    TZS: { name: "Tanzanian Shilling", symbol: "TSh" },
    UGX: { name: "Ugandan Shilling", symbol: "USh" },
    USD: { name: "United States Dollar", symbol: "$" },
    ZAR: { name: "South African Rand", symbol: "R" },
    ZMW: { name: "Zambian Kwacha", symbol: "ZK" }
};


export type ProgressStatus =
    | "idle"
    | "progress"
    | "done"
    | "failed";

export type Account = {
    accountName: string;
    bankName: string;
    sortCode: string;
    accountNumber: string;
    accountType: string;
    currency: "USD" | "NGN";
};

export type ALLOWED_CURRENCIES = "USD" | "NGN" | "ZAR" | "KES";

export function formatValue(value) {
    if (value === "MERCHANTPAYMENT") {
        return "Merchant payment";
    }
    if (value === "GENERAL") {
        return "General";
    }
    if (value === "WFUNDING") {
        return "Wallet funding";
    }
    if (value === "SERVICEFEE") {
        return "Service fee";
    }

    if (value === "BANKTRANSFER") {
        return "Bank transfer";
    }
    if (value === "FOREIGNBANKTRANSFER") {
        return "Bank transfer";
    }
    if(value === "BAASWITHDRAW"){
        return "Bass withdraw"
    }
    return value
        .split(/([A-Z]+)(?=[A-Z][^A-Z])/g)
        .join(" ");
}

export const getAvailableCurrencies = (
    countryName,
    country,
    setSelectedCurrency,
) => {
    const selectedCountryData = country.find(
        (country) =>
            country.destinationCountry === countryName,
    );
    if (selectedCountryData) {
        setSelectedCurrency(
            selectedCountryData.availableCurrency,
        );
    } else {
        setSelectedCurrency([]);
    }
};

export const nextVariants = {
    hidden: {
        x: "10vw",
    },
    visible: {
        x: 0,
    },
    transition: {
        type: "spring",
        stiffness: 200,
    },
};

export const formatStatus = (data: string) => {
    if (typeof data === "string" && data === "pending") {
        return "In progress";
    } else {
        return data;
    }
};

export const formatBool = (data) => {
    if (data === true) {
        return "successful";
    } else if (data === false) {
        return "pending";
    } else {
        return "NAN";
    }
};

export const removeLastCharacter = (arg: string) => {
    if (typeof arg === "string" && arg?.length > 0) {
        return arg.slice(0, -1);
    }
    return arg;
};

export const USDTableColumns = [
    {
        title: "Beneficiary name",
        dataIndex: "beneficiary",
        key: "beneficiary",
    },
    {
        title: "Account number",
        dataIndex: "account",
        key: "account",
    },
    {
        title: "Transaction type",
        dataIndex: "transaction_type",
        key: "transaction_type",
    },
    {
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
    },
    {
        title: "Destination amount",
        dataIndex: "destinationAmount",
        key: "destinationAmount",
    },
    {
        title: "Source amount",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text: string) => (
            <TransactionStatus text={text} />
        ),
    },
];

export const tableColumns = [
    {
        title: "Beneficiary name",
        dataIndex: "beneficiary",
        key: "beneficiary",
    },
    {
        title: "Account number",
        dataIndex: "account",
        key: "account",
    },

    {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Fee",
        dataIndex: "fee",
        key: "fee",
    },

    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text: string) => (
            <TransactionStatus text={text} />
        ),
    },
];

export const payIntableColumns = [
    {
        title: "Total amount",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: "Transaction type",
        dataIndex: "type",
        key: "type",
    },
    {
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
    },
    {
        title: "Transaction ID",
        dataIndex: "transId",
        key: "transId",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Narration",
        dataIndex: "narration",
        key: "narration",
    },
    {
        title: "Fee",
        dataIndex: "fee",
        key: "fee",
    },

    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text: string) => (
            <TransactionStatus text={text} />
        ),
    },
];

export function capitalizeName(name) {
    if (typeof name !== "string" || name.length === 0) {
        return "";
    }

    return name.charAt(0).toUpperCase() + name.slice(1);
}

export const extractedString = (original) => {
    if (
        original ===
        "Funding wallet from settlement balance"
    ) {
        return "Funding from settlement";
    }
    return original;
};




export const getExportData = async (data, currency, tab) => {     
    if (currency === "NGN" && tab === 'Debit') {
        return data?.foreignBankTransferRequests?.map(
            (item) => ({
                beneficiary: item.accountHolderName || 'N/A',
                amount: formatCurrencyCodeAmount(
                    item?.sourceCurrency,
                    item?.amount,
                ) || 'N/A',
                account: item.accountNumber || 'N/A',
                status: item.payoutStatus || 'N/A',
                date: dayjs(item.updatedAt).format(
                    "DD.MM.YYYY - HH:mm",
                ) || 'N/A',
                destinationCurrency:
                    item?.destinationCurrency || 'N/A',
            }),
        );
    }
     if((currency === "ZAR" && tab === "Credit" )|| (currency === "KES" && tab === "Credit")){
       return data?.map((data) => ({
            "Amount": data?.amount,
            "Currency": currency,
            "Type": data?.type,
             "Rate": data?.rate || "N/A",
            "Transaction Id": data?.requestId || "N/A",
            "Date": dayjs(data?.createdAt).format("DD.MM.YYYY - HH:mm"),
            "Narration": data?.narration || "business wallet funding",
            "Status": data?.payoutStatus
        }));

    
    }if((currency === "ZAR" && tab === "Debit") || currency === "KES" && tab === "Debit"){
        return data?.map((data) => ({

           "Befneficiary": 
            data?.accountName?.length > 1
                ? data?.accountName
                : "N/A",
        "Account":
            data?.accountNumber || "N/A",
        "Amount":
            formatCurrencyAmount(
                data?.wallet?.currency,
                data?.amount,
            ) || "N/A",
        "Date":
            dayjs(data.createdAt).format(
                "DD.MM.YYYY - HH:mm",
            ) || "N/A",
        "Status": data?.payoutStatus || "N/A",
        
        
        }))
    
    }
    
    else if(currency === 'USD' && tab === 'Debit'){
        return data?.content?.map((item) => ({
             beneficiary: capitalizeName(item.merchantBeneficiary.beneficiaryName) || 'N/A',
            account: item.merchantBeneficiary.accountNumber || 'N/A',
            amount: formatCurrencyAmount(item.sourceCurrency, item.sourceAmount) || 'N/A',
            date: dayjs(item.createdAt).format("DD.MM.YY - HH:mm"),
            status: item.bulkMerchantRequestStatus || 'N/A',
            rate: item.buyingRate || 'N/A',
            transaction_type: 'Wire transfer',
            narration: item?.narration || 'N/A',
            transactionId: item.ref,
            destinationAmount: formatCurrencyAmount(item.destinationCurrency, item.destinationAmount) || 'N/A',

        }));
    
    }

    else{
        return data?.walletTransactions?.map(
            (item) => ({
                transId: 
                    item.walletTransaction
                        .walletTnxId || 'N/A',
                type: formatValue(
                    item.walletTransaction
                        .userTransactionType,
                ) || 'N/A',
                rate: item.rate || 'N/A',
                amount: formatCurrencyCodeAmount(
                    item.walletTransaction
                        .toWallet.currency,
                    item.walletTransaction
                        .amount,
                ) || 'N/A',
                narration: extractedString(item?.walletTransaction.narration) || 'N/A',
                status: item
                    .walletTransaction
                    .tnxStatus || 'N/A',
                date: dayjs(
                    item.walletTransaction
                        .createdAt,
                ).format(
                    "DD.MM.YYYY - HH:mm",
                ) || 'N/A',
            }),
        );
    
    }
};


export const exporWalletXlX = async (transactions, curr, tab) => {
    let data;
    data = await getExportData(transactions, curr, tab);

    

    const exportType = exportFromJSON.types.xls;
    const fileName = `${tab} ${curr} Transaction`;
    exportFromJSON({
        data,
        fileName,
        exportType,
    });
};



export const eportWalletTransaction = async (transactions, curr, tab) => {
    let data;
    data = await getExportData(transactions, curr, tab);
    

    const exportType = exportFromJSON.types.csv;
    const fileName = `${tab} ${curr} Transaction`;
    exportFromJSON({
        data,
        fileName,
        exportType,
    });
};

export const initial = {opacity: 0, x: "60%" };
export const animate = { opacity: 1, x: "0", };
export const exit = {opacity: 0, x: "60%"  };


export function getCountryCode(countryName: any) {
    for (const code in countries) {
        if (countries[code].name.toLowerCase() === countryName.toLowerCase()) {
            return code;
        }
    }
    return null;
}