import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { InputBox } from "../../../../../../../../../components/balances/PayoutModal/style";
import { Input } from "../../../../../../../../../components/common/Input";
import { motion } from "framer-motion";
import { Form, ButtonWrapper, Note } from "../style";
import { Button } from "../../../../../../../../../components/common/Button";
import { nextVariants } from "../../../../../../../utils";
import CustomSelect from "../../../../../../../../../components/common/CustomSelect";
import {
    getAllBankAccount,
    getBankAccountDetail,
    resolveZarAccount,
} from "../../../../../../../api";
import ErrorMessage from "../../../../../../../../../components/ErrorMessage";
import Loader from "../../../../../../../../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../../../../../../../../context/NetworkErrorAlert";
import { useAppSelector } from "../../../../../../../../../app/hooks";
import { InputPhone } from "../../../../../../../../../components/common/InputPhone";

interface FormInputs {
    bankName?: string;
    accountType?: string;
    accountName?: string;
    phone?: string;
    description?: string;
    accountNumber?: string;
    bankCode?: string;
    label?: string;
    value?: string;
    identityNumber?: string;
    account_name?: string;
    account_number?: string;
    zarAccountName?: string;
    zarAccountNumber?: string;
    identityType?: string;
    IdentityDoc?: string;
    phoneNumber?: string;
    recipientAddress?:string
}

interface LocalTransferProps {
    onNext: () => void;
    setlocalBankInfo: (data: FormInputs) => void;
    localBankInfo: FormInputs;
    setBankName?: (data: string) => void;
    setInitialAccountName?: (data: string) => void;
    initialAccountName?: string;
    identity?: string;
    setIdentity?: (data: string) => void;
    accountType?: string;
    setAccountType?: (data: string) => void;
}

const BankInfo = ({
    onNext,
    setlocalBankInfo,
    localBankInfo,
    setBankName,
    setInitialAccountName,
    initialAccountName,
    identity,
    setIdentity,
    accountType,
    setAccountType,
}: LocalTransferProps) => {
    const [formData, setFormData] = useState<FormInputs>(
        localBankInfo || {},
    );
    const { currencyCode } = useParams();
    const ZAR = currencyCode === "ZAR";
    const KES = currencyCode === "KES";
    const [banks, setBanks] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>("no-error");
    const { onShowAlert } = useContext(
        NetworkErrorAlertContext,
    );

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<FormInputs>({
        defaultValues: {
            ...formData,
            accountName: initialAccountName,
        },
    });

    const watchAll = watch();

    const accountNumberValue: any = watch("accountNumber");
    const { businessId } = useAppSelector(
        (state) => state.users || {},
    );

    const updateIdentiTy = (data) => {
        if (data === "IdentityNumber") {
            return "identityNumber";
        }
        return data;
    };

    const accountTypes = [
        { value: "business", label: "Business" },
        { value: "personal", label: "Personal" },
    ];

    const identityOptions = [
        {
            value: "IdentityNumber",
            label: "Identity number",
        },
        {
            value: "passportNumber",
            label: "Passport number",
        },
    ];

    const handleBankChange = (selectedBankCode) => {
        setValue("bankCode", selectedBankCode);
        const selectedBank = selectBanks.find(
            (bank) => bank.value === selectedBankCode,
        );
        setBankName(selectedBank?.label);

        setlocalBankInfo(selectedBank);
    };

    const handleAccountType = (option) => {
        setAccountType(option);
    };

    const handleIdentityType = (data) => {
        setIdentity(data);
    };

    const removeLastCharacter = (arg: string) => {
        if (typeof arg === "string" && arg?.length > 0) {
            return arg.slice(0, -1);
        }
        return arg;
    };

    const accountLabel =
        accountType === "business"
            ? "Business registration number"
            : "Identity or passport number";

    const identityLabel =
        identity === "IdentityNumber"
            ? "Enter the identity number"
            : "Enter the passport number";

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                await getAllBankAccount({
                    currency: currencyCode,
                }).then((data) => {
                    setBanks(data.data ? data.data : data);
                    setLoading(false);
                });
            } catch (error) {
                setLoading(false);
            }
        }

        fetchData();
    }, []);
        
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const modifiedCurrencyCode =
                    removeLastCharacter(currencyCode);

                await getBankAccountDetail({
                    accountNumber: accountNumberValue,
                    bankCode: localBankInfo?.value,
                    countryCode: modifiedCurrencyCode,
                }).then((data) => {
                    setlocalBankInfo(data);
                    setInitialAccountName(
                        data.account_name,
                    );
                    setLoading(false);
                    setError("no-error");
                });
            } catch (error) {
                setError("error");
                setLoading(false);
            }
        }

        if (
            localBankInfo?.value &&
            accountNumberValue?.length === 10
        ) {
            fetchData();
        }
    }, [accountNumberValue, localBankInfo?.value]);

    const selectBanks = Array.isArray(banks)
        ? banks.map((bank) => ({
              label: bank.name,
              value: bank.code,
          }))
        : [];

    const resolveZARAccountNumber = async () => {
        setLoading(true);
        const ZarPayload = {
            accountNumber: watchAll.zarAccountNumber,
            accountName: watchAll.zarAccountName,
            bankCode: watchAll.bankName,
            accountType: accountType,
            documentNumber:
                watchAll.identityNumber ||
                watchAll.identityType,
            countryCode: removeLastCharacter(currencyCode),
            documentType:
                updateIdentiTy(identity) ||
                "businessRegistrationNumber",
            businessId: businessId
        }

        const KESPayload = 
            {
                country: removeLastCharacter(currencyCode),
                bankCode: watchAll.bankName,
                accountName: watchAll.zarAccountName,
                accountNumber: watchAll.zarAccountNumber,
                currency: currencyCode,
                bankName: watchAll.bankName,
                description: watchAll.description,
                type:accountType

        }
        await resolveZarAccount(ZAR ? ZarPayload : KESPayload)
            .then((res) => {
                setLoading(false);
                onNext();
            })
            .catch((errr) => {
                onShowAlert(
                    "",
                    errr?.response?.data?.error,
                );
                setLoading(false);
            });
    };

    const handleUpdatePayment = () => {
        if (ZAR) {
            resolveZARAccountNumber();
        } else if (currencyCode === "NGN" || KES) {
            onNext();
        }
    };    

    return (
        <motion.div
            variants={nextVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Loader isLoading={loading} />
            <Form>
                <InputBox>
                    <Controller
                        name="bankName"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <CustomSelect
                                placeholder={`${
                                    currencyCode === "GHS"
                                        ? "Account type"
                                        : "Select bank"
                                }`}
                                $width="100%"
                                field={field}
                                disableLabel
                                options={selectBanks}
                                onChange={handleBankChange}
                                showSearch
                                filterOption={(
                                    inputValue,
                                    option,
                                ) =>
                                    option.label
                                        .toLowerCase()
                                        .includes(
                                            inputValue.toLowerCase(),
                                        )
                                }
                            />
                        )}
                    />
                    {errors.bankName?.type ===
                        "required" && (
                        <span>
                            {`${
                                currencyCode === "GHS"
                                    ? "Please select the account type"
                                    : "Please input the beneficiary's Bank name"
                            }`}
                        </span>
                    )}
                </InputBox>

                {currencyCode === "GHS" && (
                    <div>
                        <InputBox>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required:
                                        "Please enter the beneficiary's phone number",
                                    validate: (value) =>
                                        value.length ===
                                            10 ||
                                        "Phone number must be 10 digits",
                                }}
                                render={({ field }) => (
                                    <Input
                                        type="number"
                                        label="Phone number"
                                        field={field}
                                        labelColor="#FFFFFF"
                                        disableLabel
                                    />
                                )}
                            />

                            {errors.phone && (
                                <span>
                                    {errors.phone.message}
                                </span>
                            )}
                        </InputBox>
                    </div>
                )}

                {currencyCode === "NGN" && (
                    <div>
                        <InputBox>
                            <Controller
                                name="accountNumber"
                                control={control}
                                rules={{
                                    required: true,
                                    pattern: /^\d*$/,
                                }}
                                render={({ field }) => (
                                    <Input
                                        type="number"
                                        label="Account Number"
                                        field={field}
                                        labelColor="#FFFFFF"
                                        disableLabel
                                    />
                                )}
                            />
                            {watchAll?.accountNumber &&
                                (watchAll?.accountNumber
                                    ?.length !== 10 &&
                                currencyCode === "NGN" &&
                                watchAll?.accountNumber
                                    ?.length !== 0 ? (
                                    <ErrorMessage message="Account number must be 10 digits" />
                                ) : (
                                    error === "error" && (
                                        <ErrorMessage message="Account number is not valid" />
                                    )
                                ))}

                            {errors.accountNumber?.type ===
                                "required" && (
                                <span>
                                    Please input the
                                    beneficiary&apos;s
                                    account number
                                </span>
                            )}
                        </InputBox>

                        <InputBox>
                            <Controller
                                name="accountName"
                                control={control}
                                rules={{
                                    required:
                                        currencyCode !==
                                        "NGN",
                                }}
                                render={({ field }) => (
                                    <Input
                                        type="text"
                                        label="Account Name"
                                        value={
                                            initialAccountName
                                        }
                                        labelColor="#FFFFFF"
                                        field={field}
                                        disableLabel
                                        disabled={
                                            currencyCode ===
                                            "NGN"
                                        }
                                    />
                                )}
                            />

                            {errors.accountName?.type ===
                                "required" && (
                                <span>
                                    Please input the
                                    beneficiary&apos;s
                                    account name
                                </span>
                            )}
                        </InputBox>
                    </div>
                )}

                {(ZAR || KES )&& (
                    <div>
                        <InputBox>
                            <Controller
                              name="zarAccountNumber"
                              control={control}
                              rules={{
                                  required: "Please enter the beneficiary's account number",
                                  validate: (value) => {
                                      if (currencyCode === "ZAR") {
                                          return value.length === 10 || "Account number must be 10 digits";
                                      }
                                      return true;
                                  },
                              }}
                              render={({ field }) => (
                                <Input
                                  type="number"
                                  label="Account number"
                                  labelColor="#FFFFFF"
                                  field={field}
                                  disableLabel
                                />
                              )}
                            />

                            {errors.zarAccountNumber && (
                              <span>
            {errors.zarAccountNumber.message}
        </span>
                            )}
                        </InputBox>


                        <InputBox>
                            <Controller
                                name="zarAccountName"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <Input
                                        type="text"
                                        label="Account name"
                                        labelColor="#FFFFFF"
                                        field={field}
                                        disableLabel
                                    />
                                )}
                            />

                            {errors.zarAccountName?.type ===
                                "required" && (
                                <span>
                                    Please enter the
                                    beneficiary&apos;s
                                    account name
                                </span>
                            )}
                        </InputBox>

                        <InputBox>
                            <Controller
                                name="accountType"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <CustomSelect
                                        placeholder="Account Type"
                                        $width="100%"
                                        field={field}
                                        disableLabel
                                        options={
                                            accountTypes.splice(KES ? 1 : 0)
                                        }
                                        onChange={
                                            handleAccountType
                                        }
                                    />
                                )}
                            />
                            {errors.accountType?.type ===
                                "required" && (
                                <span>
                                    The account type is
                                    required
                                </span>
                            )}
                        </InputBox>

                        {accountType === "personal" && !KES && (
                            <InputBox>
                                <Controller
                                    name="IdentityDoc"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <CustomSelect
                                            placeholder="Select document type"
                                            $width="100%"
                                            field={field}
                                            disableLabel
                                            options={
                                                identityOptions
                                            }
                                            onChange={
                                                handleIdentityType
                                            }
                                        />
                                    )}
                                />
                                {errors.accountType
                                    ?.type ===
                                    "required" && (
                                    <span>
                                        The document type is
                                        required
                                    </span>
                                )}
                            </InputBox>
                        )}

                        {accountType === "business" && (
                            <InputBox>
                                <Controller
                                    name="identityNumber"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            label="Business registration number"
                                            value={
                                                initialAccountName
                                            }
                                            labelColor="#FFFFFF"
                                            field={field}
                                            disableLabel
                                        />
                                    )}
                                />

                                {errors.identityNumber
                                    ?.type ===
                                    "required" && (
                                    <span>
                                        {`Please enter the
                                       ${accountLabel}`}
                                    </span>
                                )}
                            </InputBox>
                        )}
                    </div>
                )}

                {ZAR && (
                    <InputBox>
                        <Controller
                            name="recipientAddress"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    label="Recipient address"
                                    labelColor="#FFFFFF"
                                    field={field}
                                    disableLabel
                                />
                            )}
                        />

                        {errors.recipientAddress?.type ===
                            "required" && (
                            <span>
                                {`Please enter the
                                       Recipient Address`}
                            </span>
                        )}
                    </InputBox>
                )}

                {ZAR && (
                    <InputBox>
                        <Controller
                            name="phoneNumber"
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <InputPhone
                                    label="Phone number"
                                    field={field}
                                    hasError={
                                        errors?.phoneNumber
                                            ?.type ===
                                        "required"
                                    }
                                    defaultCountry="ZA"
                                />
                            )}
                        />
                        {errors?.phoneNumber?.type ===
                            "required" && (
                            <span className="error">
                                Phone number is required
                            </span>
                        )}
                    </InputBox>
                )}

                {currencyCode === "ZAR" &&
                    identity &&
                    accountType !== "business" && (
                        <InputBox>
                            <Controller
                                name="identityType"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <Input
                                        type="text"
                                        label={
                                            identityLabel
                                        }
                                        field={field}
                                        labelColor="#FFFFFF"
                                        disableLabel
                                    />
                                )}
                            />
                            {errors.identityType?.type ===
                                "required" && (
                                <span>
                                    The field is required
                                </span>
                            )}
                        </InputBox>
                    )}

                <InputBox>
                    <Controller
                        name="description"
                        control={control}
                        rules={{
                            required: false,
                        }}
                        render={({ field }) => (
                            <Input
                                type="text"
                                label="Payment description (optional)"
                                field={field}
                                labelColor="#FFFFFF"
                                disableLabel
                            />
                        )}
                    />
                </InputBox>
                <ButtonWrapper>
                    <Button
                        label="Continue"
                        theme="primary"
                        type="submit"
                        height="48px"
                        fontSize="14px"
                        disabled={error === "error"}
                        onClick={handleSubmit((data) => {
                            setlocalBankInfo(data);
                            setFormData(data);
                            handleUpdatePayment();
                        })}
                    />
                </ButtonWrapper>
            </Form>
        </motion.div>
    );
};

export default BankInfo;
