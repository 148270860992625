import React from "react";
import { DateWrapper } from "./style";
import "./style.css";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { ReactComponent as PrevIcon } from "./assets/arrow left.svg";
import { ReactComponent as NextIcon } from "./assets/arrow-right.svg";

interface InputProps {
  value?: any;
  placeholder?: string;
  onChange?: any;
  disabledDate?: any;
}

const CustomDatePicker = ({ ...props }: InputProps) => {
  return (
    <DateWrapper
      {...props}
      suffixIcon={<CalendarIcon />}
      dropdownClassName="picker-container"
      prevIcon={<PrevIcon />}
      nextIcon={<NextIcon />}
      superPrevIcon={null}
      superNextIcon={null}
    />
  );
};

export default CustomDatePicker;
