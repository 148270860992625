import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  background-color: #f9f9f9;
  transition: display 0.2s ease-in;
  display: flex;
  flex-direction: column;

  ${(props: { $isExpanded: "none" | "min" | "max" }) => {
    if (props.$isExpanded === "max") {
      return css`
        width: 200px;
        padding: 24px 0;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        min-height: 100vh;
        z-index: 7;
        flex-shrink: 0;
        height: 100%;
        & span[title="link-label"],
        & span[title="hide-label"] {
          display: block;
        }
      `;
    } else if (props.$isExpanded === "min") {
      return css`
        width: 90px;
        padding: 48px 0;
        text-align: center;
        & span[title="link-label"],
        & span[title="hide-label"],
        & span[title="hide-support-text"] {
          display: none;
          margin-left: 0 !important;
        }
        & a {
          justify-content: center;
        }
      `;
    } else {
      return css`
        padding: 0px;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        width: 0px;
        flex-shrink: 0;
      `;
    }
  }};
`;


export const SectionWrappers = styled.div`
  overflow-y: auto;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 24px;

  &:last-child{
    margin-bottom: 70px;
  }
`;

export const SectionTitle = styled.p`
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #858F99;
  margin-bottom: 5px;
  padding-left: ${(props: { $isExpanded }) =>
    props.$isExpanded === "max" ? "24px" : "0"};
`;

export const SectionLinks = styled.div`
  display: block;
  
  a {
    font-family: "Inter";
    font-size: 16px;
    line-height: 24px;
    padding: ${(props: { $isExpanded }) =>
      props.$isExpanded === "max" ? "13px 0 13px 24px" : "14px 0"};

    svg {
      height: 24px;
      object-fit: cover;
    }
  }
`;


export const MenuBottom = styled.div`
  position: absolute;
  bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  font-family: "Inter";;
  font-style: normal;
  font-size: 12px;
  line-height: 15.6px;
  padding-top: 24px;
  background-color: #f9f9f9;
  border-top: 1px solid #F0F0F0;
`;

export const Feature = styled.button`
  color: #FFFFFF;
  background: #0A9730;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  margin-left: 8px;
`;

export const SupportLink = styled.button`
  padding-left: ${(props: { $isExpanded: "none" | "min" | "max" }) =>
    props.$isExpanded === "max" ? "15px" : 0};
  height: 40px;
  background: #ef2c5a;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  width: ${(props: { $isExpanded: "none" | "min" | "max" }) =>
    props.$isExpanded === "max" ? "160px" : "40px"};

  a {
    display: flex;
    align-items: center;
    justify-content: ${(props: { $isExpanded: "none" | "min" | "max" }) =>
      props.$isExpanded === "min" ? "center" : "flex-start"};
    color: #fff;

    svg {
      height: 24px;
      object-fit: cover;
    }

    span {
      margin-left: 12px;
      font-weight: 600;
      display: ${(props) => (props.$isExpanded === "max" ? "block" : "none")};
    }
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  background-color: transparent;
  border: 0;
  outline: 0;

  svg {
    height: 20px;
  }

  @media (min-width: 993px) {
    display: none;
  }
`;

export const HideButton = styled.button`
  display: none;
  align-items: center;
  padding-left: ${(props: { $isExpanded: "none" | "min" | "max" }) =>
    props.$isExpanded === "max" ? "20px" : 0};
  border: 1px solid #000000;
  border-radius: 6px;
  width: ${(props: { $isExpanded: "none" | "min" | "max" }) =>
    props.$isExpanded === "max" ? "160px" : "40px"};
  height: 40px;
  background-color: transparent;
  font-weight: 600;

  & > span {
    margin-left: 16px;
  }

  svg {
    height: 24px;
  }

  transform: ${(props: { $isExpanded }) =>
    props.$isExpanded === "max" ? "unset" : "rotate(180deg)"};
  @media (min-width: 993px) {
    display: flex;
    justify-content: ${(props: { $isExpanded: "none" | "min" | "max" }) =>
      props.$isExpanded === "min" ? "center" : "flex-start"};
  }
`;

export const LogoWrapper = styled.div`
  margin-bottom: 32px;
  padding-left: ${(props: { $isExpanded }) =>
    props.$isExpanded === "max" ? "28px" : "0"};
  display: flex;
  align-items: center;
  justify-content: ${(props: { $isExpanded }) =>
    props.$isExpanded === "max" ? "space-between" : "center"};

  & ${HideButton} {
    width: 40px;
    display: flex;
  }

  @media (min-width: 993px) {
    & ${HideButton} {
      display: none;
    }
  }
`;
