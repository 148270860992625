import React from "react";
import AccountDetails from "../../../../components/AccountDetails/AccountDetails";
import { Heading } from "../../style";
import { useParams } from "react-router-dom";

interface Props {
    title: string;
    narration: string;
    narrationDetail: string;
    callBack: () => void;
}

const KlashaAccount = ({
    title,
    narration,
    narrationDetail,
    callBack,
}: Props) => {
    const { currencyCode } = useParams();
    const isZAR = currencyCode === "ZAR";
    const bankName = isZAR ? "Nedbank" : "UBA";
    const accountNumber = isZAR
        ? "1227634846"
        : "55010160015196";
    const accountType = isZAR ? "Nedszajj" : "UNAFKENA";
    return (
        <div>
            <Heading>{title}</Heading>
            <AccountDetails
                accountName="Klasha Technologies Ltd"
                bankName={bankName}
                accountNumber={accountNumber}
                accountType={accountType}
                narration={narration}
                narrationDetail={narrationDetail}
                callBack={callBack}
            />{" "}
        </div>
    );
};

export default KlashaAccount;
