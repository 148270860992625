import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "@reach/window-size";
import { formatLongString } from "../../../../utils/formatString";
import exportFromJSON from "export-from-json";
import { formatValue } from "../../utils";
import CustomTable from "../../../../components/common/CustomTable";
import TransactionStatus from "../../../../components/transactions/components/TransctionStatus";
import { walletTransactions } from "../../redux/slice";
import Tools from "./components/Tools";
import { StyledTransactionsTable, MobileBody } from "./style";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/hooks";
import { formatCurrencyAmount, formatCurrencyCodeAmount } from "../../../../utils/currency";
import dayjs from "dayjs";
import Loader from "../../../../components/common/Loader";
import { EmptyWrapper, EmptyWrapperTitle, EmptyWrapperSubTitle } from "../../../Balances/style";
import EmptyMobileCard from "../../../../components/emptyStates/EmptyMobileCard";
import WalletCard from "../WalletCard";
import { selectUserRoleAndPermissions } from "../../../Settings/Team/redux/slice";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";

const EmptyBalanceDescription = () => {
  return (
    <EmptyWrapper>
      <EmptyWrapperTitle>No transactions yet</EmptyWrapperTitle>
      <EmptyWrapperSubTitle>All received or sent transactions will appear here</EmptyWrapperSubTitle>
    </EmptyWrapper>
  );
};


const tableColumns = [
  {
    title: "Total amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Transaction type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Transaction ID",
    dataIndex: "transId",
    key: "transId",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => (
      <TransactionStatus text={text} />
    ),
  },
];

interface DateProps {
  startDate: Date;
  endDate: Date;
}

const TransactionsTable = () => {
  const { width } = useWindowSize();
  const { businessId } = useAppSelector(
    (state) => state.users,
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [allWallet, setAllWallet] = useState<any>();
  const [select, setSelect] = useState<any>();
  const [status, setStatus] = useState<number>();
  const [date, setDate] = useState<DateProps>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState('');
  const [exportData, setExportData] = useState(null);
  const {role, permissions } = useAppSelector(
    selectUserRoleAndPermissions,
);

const { onShowAlert} = useContext(
    NetworkErrorAlertContext,
  );

const hasDownloadPermission =
        permissions?.["wallet"]?.includes("EXPORT_WALLET_STATEMENT");


  const startDate =
    date?.[0] && dayjs(date[0])?.format("YYYY-MM-DD");
  const endDate =
    date?.[1] && dayjs(date[1])?.format("YYYY-MM-DD");

  const onPageChange = (page: number) => {
    setPageNumber(page);
  };

  const filter = () => {
    getTransactions();
  };

  const handleReset = async () => {
    setStatus(null);
    setSelect(null);
  };

  const getTransactions = () => {
    const data = {
      businessId: businessId,
      tnxStatus: status || select,
      startDate: startDate,
      endDate: endDate,
      pageNumber: pageNumber,
      pageSize: 10,
    };

    setLoading(true);
    dispatch(
      walletTransactions({
        filter: data,
      }),
    )
      .then((response) => {
        setData(response?.payload);
        const { payload } = response;
        const walletData = payload?.walletTransactions;
        setExportData(walletData)

        const tableData = walletData?.map((item) => ({
          key: item.walletTransaction.id,
          no: item.id,
          transId: formatLongString(
            item.walletTransaction?.walletTnxId,
            25,
          ),
          type: formatValue(
            item.walletTransaction.userTransactionType,
          ),
          amount: formatCurrencyAmount(
            item.walletTransaction.toWallet.currency,
            item.walletTransaction.amount,
          ),
          status: item.walletTransaction.tnxStatus,
          date: dayjs(
            item.walletTransaction.createdAt,
          ).format("DD.MM.YYYY - HH:mm"),
        }));
        setAllWallet(tableData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getExportData = async () => {
    return exportData?.map((item) => ({
      "Transaction ID": item?.walletTransaction?.walletTnxId,
      Type:
        item?.walletTransaction?.userTransactionType ?? "-",
      Amount: item?.walletTransaction.toWallet.currency + " " + new Intl.NumberFormat('en-US').format(item?.walletTransaction?.amount),
      Status: item?.walletTransaction?.tnxStatus ?? "-",
      Date: dayjs(
        item?.walletTransaction?.createdAt,
      )?.format("DD.MM.YYYY HH:mm"),
    }));
  };

  const exportToXLS = async () => {
   if(hasDownloadPermission || role === "OWNER"){
    const data = await getExportData();
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({
      data,
      fileName: "wallet transactions",
      exportType,
    });

}else{
    onShowAlert("", "Access is denied");

}
  };

  const exportToCSV = async () => {
    if(hasDownloadPermission || role === "OWNER"){
    const data = await getExportData();
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({
      data,
      fileName: "wallet transactions",
      exportType,
    });
    
    }else{
     onShowAlert("", "Access is denied");
    }
    
  };

  useEffect(() => {
    if (businessId !== null) {
        getTransactions();
    }
}, [businessId, select, pageNumber]);

  const text = `Transactions`;

  const filteredWallet = allWallet?.filter((item) =>
    Object.values(item).some(
      (val) =>
        typeof val === "string" &&
        val.toLowerCase().includes(search.toLowerCase())
    )
  );

  return (
    <StyledTransactionsTable>
      <Loader isLoading={loading} />
      <h3>{text}</h3>

     {allWallet && allWallet.length > 0 ?
        <Tools
          search={search}
          setSearch={setSearch}
          onStatusChange={(data) => setStatus(data)}
          onDateChange={(date) => setDate(date)}
          onFilterButtonClick={filter}
          onResetButtonClick={handleReset}
          onSelectChange={(data) => setSelect(data)}
          onXLSButtonClick={exportToXLS}
          onCSVButtonClick={exportToCSV}
        />
      : null}
     
       {width > 767 ? (
           <CustomTable
            extraEmptyChildren={<EmptyBalanceDescription />}
            columns={tableColumns}
            dataSource={filteredWallet}
            currentPage={pageNumber}
            totalItemCount={data?.totalCount}
            onPageChange={onPageChange}
         />
        ) : (
          <MobileBody>
            {filteredWallet?.length ? filteredWallet.map((wal) => (
              <WalletCard
                key={wal.key}
                transId={wal.transId}
                type={wal.type}
                date={wal.date}
                amount={wal.amount}
                status={wal.status.toLowerCase()}
              />
            )) : 
            <EmptyMobileCard 
              title="No transactions yet"
              subText="All received or sent transactions will appear here"
            />
            }
          </MobileBody>
        )}
    </StyledTransactionsTable>
  );
};

export default TransactionsTable;
