import { motion } from "framer-motion";
import styled from "styled-components";
import { Input } from "antd";

export const OTPWrapper = styled.div`
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 50%;
    align-items: center;
    flex-direction: column;
    margin: auto;
    margin-top: 40px;
    @media (max-width: 768px) {
        width: 100%;
    }

    .rate-box {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .btn {
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px; /* 130% */
        margin-top: 20px;
    }
`;

export const CurrencyTypeWrapper = styled.div`
    display: flex;
    border-bottom: 1px solid #f0f0f0;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        width: 200px;
        text-align: center;
        width: 100%;
        position: relative;
        top: 18px;
        cursor: pointer;
        padding-bottom: 12px;

        &.active {
            color: #000000;
            border-bottom: 2px solid black;
            position: relative;
            top: 18px;
        }

        &.inactive {
            color: #565c69;
        }
    }

    @media (min-width: 768px) {
        gap: 64px;
        margin-top: 10px;
    }
`;
export const CurrencyWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 30px;
.same-wallet{
    .ant-select-arrow{
        display: none;
    }
    .ant-select-selection-item{
        margin-left: 20px;

    }
    }
}
`;

export const RateBox = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f9f9f9;

    span {
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
    }

    .fee-box {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;

export const Flex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Header = styled.h1`
    font-family: Athletics;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: center;
`;

export const Beneficiaries = styled.div`
    width: 100%;
    height: 280px;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow-y: auto;

    & > * {
        flex: 1 1 calc(50% - 20px);
        box-sizing: border-box;
    }
`;

export const MainTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9e9e9;
    p {
        color: #000000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
    }

    span {
        font-size: 12px;
        color: var(--primary-pink);
        font-weight: 600;
        line-height: 15.6px;
        cursor: pointer;
    }
`;

export const TextArea = styled(Input.TextArea)`
    margin: 15px 0 45px 0;
    width: 100%;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    color: #000000;

    &:hover {
        border: 1px solid #a3a3a3;
    }

    &:focus {
        border: 1px solid #000000;
        box-shadow: none;
    }
`;

export const NotFound = styled.div`
    width: 100%;
    margin-top: 20px;

    p {
        font-weight: 500;
        line-height: 16px;
        color: #000000;
        font-size: 14px;
        padding-bottom: 10px;
        border-bottom: 1px solid #000000;
        text-align: left;
        margin-bottom: 16px;
    }
`;

export const ContentWrapper = styled.div`
    padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .button {
        margin-top: 30px;
    }

    @media (min-width: 768px) {
        width: 440px;
        margin: 0 auto;
    }
    @media (min-width: 1024px) {
        width: 70%;
    }
`;

export const MainHeader = styled.header`
    margin-bottom: 20px;

    h1 {
        font-family: "Athletics";
        font-size: 28px;
        font-weight: 500;
        line-height: 34px;
        letter-spacing: -0.56px;
        color: #000000;
        margin: 0;
    }

    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        color: #767676;
        margin: 8px auto 0 auto;

        a {
            color: var(--primary-pink);
            text-decoration: underline;
        }
    }

    @media (min-width: 768px) {
        p {
            max-width: 80%;
        }
    }
`;

export const Title = styled.p`
    font-family: "Athletics";
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000000;
    margin: 0;
`;

export const BeneficiaryWrapper = styled.div`
    width: 100%;
`;

export const DetailsSectionHeader = styled.div`
    padding-bottom: 8px;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const DetailsLabel = styled.p`
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #adadad;
`;

export const DetailsValue = styled.p`
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    color: #000000;
`;

export const DetailsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SectionTitle = styled.p`
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
`;

export const EditButton = styled.button`
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    color: #ef2c5a;
`;

export const DetailsBox = styled.div`
    padding: 20px 40px;
    font-family: Athletics;
    border: 1px solid #969696;
    box-sizing: border-box;
    border-radius: 8px;
`;

export const DetailsContent = styled.div``;
