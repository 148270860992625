import React from 'react';
import { RequestAccountWrapper } from './style';
import { Button } from '../../../../../../components/common/Button';

interface PendingAccountProps{
    handleClick?: () => void;
    accountType?: string;
}

const RequestAccount = ({handleClick, accountType}: PendingAccountProps) => {
  return (
    <RequestAccountWrapper>
        <h1>Get USD account details</h1>
        <p>Please follow the steps to request for your account.</p>

        <Button label='Continue' fontSize='12px' theme='secondary' height='40px' width='100px' onClick={handleClick}/>
    </RequestAccountWrapper>
  )
}

export default RequestAccount;