import React, { useState, useContext } from "react";
import OTP from "../../../../../../components/common/OTP/index";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { OTPWrapper  } from "../styles";
import Loader from "../../../../../../components/common/Loader";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../../app/hooks";
import { sendMerchantPayment } from "../../../../redux/slice";
import { useNavigate } from "react-router-dom";
import { clearUserData } from "../../../../redux/formDataSlice";
import { encryptAlgorithm } from "../../../../../../utils/encryptionAlgorithm";

interface Props {
    setVerifyOTP?: (args: boolean) => void;
    transactionId: string;
    ResendOTP: () => void;
}

const BassOTP = ({
    setVerifyOTP,
    transactionId,
    ResendOTP
}: Props) => {
    const [otp, setOtp] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const { onShowAlert } = useContext(
        NetworkErrorAlertContext,
    );
    const navigate = useNavigate();
    const { onShowAlert: onSuccess } = useContext(
        SuccessAlertContext,
    );
    const savedUserData = useAppSelector(
        (state) => state?.formData?.userData,
    );
    const selectedBeneficiary = useAppSelector(
        (state) =>
            state?.formData?.selectedBeneficiary || null,
    );

    const convertedAmount = savedUserData?.convertedAmount !== undefined && savedUserData?.convertedAmount !== null
    ? savedUserData.convertedAmount.toFixed(4) : null

    const handleVerifyOTP = (values: string) => {
        if (values.length < 6) {
            onShowAlert(
                Error,
                "The OTP code is not complete",
            );
        } else {
            verifyUserOTP(values);
        }
    };

    const verifyUserOTP = async (otpString: string) => {
        const formData = {
            sourceCurrency: savedUserData.currency || savedUserData.sourceCurrency,
            sourceAmount: Number(convertedAmount - savedUserData?.fees),
            destinationCurrency: savedUserData.currency || savedUserData.destinationCurrency,
            destinationAmount: savedUserData.amount,
            buyingRate: savedUserData.buyingRate,
            totalFees: savedUserData.fees,
            rate: savedUserData.rate,
            paid: false,
            business: businessId,
            merchantBeneficiary: selectedBeneficiary?.id,
            transactionRef: transactionId,
            narration: "BASS merchant transfer",
            otp: otpString,
        };

        const encryptedData = encryptAlgorithm(formData);
        const payload = {
            message: encryptedData,
        };

        const action = await dispatch(
            sendMerchantPayment({
                data: payload,
            }),
        );

        if (sendMerchantPayment.fulfilled.match(action)) {
            onSuccess(
                "Successsfully sent funds to beneficiary",
            );
            setVerifyOTP(false);
            dispatch(clearUserData());
            navigate("/wallets");
        }

        if (sendMerchantPayment.rejected.match(action)) {
            onShowAlert("error", String(action.payload));
        }
    };

    const handleResendOTP = async () => {
        try {
            setIsLoading(true);
             ResendOTP();
            setIsLoading(false);
            setOtp("");
            onSuccess("Check your email for the OTP");
        } catch (error) {
            const errorObj = error?.response?.data;
            let errorMessage = "";

            if (typeof errorObj.error === "string") {
                errorMessage = errorObj.error;
            } else if (typeof errorObj === "string") {
                errorMessage = errorObj;
            } else if (
                typeof errorObj.message === "string"
            ) {
                errorMessage = errorObj.message;
            } else {
                errorMessage = "Resend Failed";
            }

            setIsLoading(false);
            onShowAlert(errorObj, errorMessage);
        }
    };

    const handleSendOtp = () => {
        handleVerifyOTP(otp)
    }

    return (
        <OTPWrapper>
            <Loader isLoading={isLoading} />

            <OTP
                value={otp}
                onChange={setOtp}
                smallText="Enter the verification code sent to your email address to send funds."
                buttonLabel="Continue"
                onClose={() => setVerifyOTP(false)}
                onClick={handleSendOtp}
                handleClick={handleResendOTP}
            />
        </OTPWrapper>
    );
};

export default BassOTP;
