import styled, { css } from "styled-components";

export const ButtonWrapper = styled.button`
  width: ${(props) => props.$width || "100%"};
  font-size: ${(props) => props.$fontSize || "16px"};
  height: ${(props) => props.$height || "56px"};
  border: unset;
  border-radius: 8px;
  font-family: "Inter";
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  outline: none;

  ${(props: {
    $theme: "primary" | "secondary" | "alternate";
    $width: string;
    $fontSize?: string;
    $height?: string;
  }) => {
    if (props.$theme === "primary") {
      return css`
        background: var(--primary-pink);
        color: #ffffff;

        &:hover{
          background-color: #ff3463;
        }

        &:disabled {
          background-color: #FABFCD;
        }
      `;
    } else if (props.$theme === "secondary") {
      return css`
        background-color: #000000;
        color: #ffffff;

        &:hover {
          box-shadow: 0 0 10px rgba(0,0,0,0.3);
        }

        &:disabled {
          background-color: #f1f1f1;
          color: #717070;
        }
      `;
    } else if (props.$theme === "alternate") {
      return css`
        background: transparent;
        border: 1px solid #000000af;
        color: #000000;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 41, 0.05);

        &:disabled {
          background-color: #f1f1f1;
          color: #717070;
        }
      `;
    }
  }}
`;
