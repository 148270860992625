import styled from "styled-components";
import { Modal } from "antd";

export const Wrapper = styled(Modal)`
  font-family: "Inter";
  width: 90% !important;
  background: #ffffff;
  border-radius: 8px;
  padding-bottom: 0;
  overflow-y: auto;

  & span[class="ant-modal-close-x"] {
    padding: 5px;
  }

  @media (min-width: 768px) {
    width: ${(props: { $width?: string }) =>
      `min(${props.$width}) !important` || "464px !important"};
  }
`;
