import React, { ChangeEvent, useState } from "react";
import { Popover } from "antd";
import {
  FilterAndButtons,
  FilterBox,
  FilterBoxFooter,
  FilterBoxItem,
  FilterBoxItemTitle,
  FilterButton,
  FilterFooterApplyButton,
  FilterFooterResetButton,
  Wrapper,
} from "./style";
import "./style.css";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import { paymentMethods, sourceMethods } from "../../utils";
import CustomDateRangePicker from "../../../common/CustomDateRangePicker";
import CustomSelectTwo from "../../../common/CustomSelect/CustomSelectTwo";
import Search from "../../../common/Search";
import Export from "../../../common/Export";

const FilterContent = ({
  onMethodChange,
  onDateChange,
  onFilterButtonClick,
  onResetButtonClick,
  onSourceChange,
}: {
  onFilterButtonClick: () => void;
  onResetButtonClick: () => void;
  onMethodChange: (val) => void;
  onSourceChange: (val) => void;
  onDateChange: (val, val2) => void;
}) => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedDate, setSelectedDate] = useState([]);
  const onResetClick = () => {
    onResetButtonClick();
    setSelectedMethod(null);
    setSelectedDate([]);
  };
  const onMethodSelectChange = (value) => {
    onMethodChange(value);
    setSelectedMethod(value);
  };
  const onSourceSelectChange = (value) => {
    onSourceChange(value);
    setSelectedSource(value);
  };
  const onDateRangeChange = (dates, dateString) => {
    onDateChange(dates, dateString);
    setSelectedDate(dates);
  };
  return (
    <FilterBox>
      <FilterBoxItem>
        <FilterBoxItemTitle>Method</FilterBoxItemTitle>
        <CustomSelectTwo
          value={selectedMethod}
          options={paymentMethods}
          onChange={onMethodSelectChange}
        />
      </FilterBoxItem>
      <FilterBoxItem>
        <FilterBoxItemTitle>Source</FilterBoxItemTitle>
        <CustomSelectTwo
          value={selectedSource}
          options={sourceMethods}
          onChange={onSourceSelectChange}
        />
      </FilterBoxItem>
      <FilterBoxItem>
        <FilterBoxItemTitle>Date range</FilterBoxItemTitle>
        <CustomDateRangePicker
          value={selectedDate}
          onChange={onDateRangeChange}
        />
      </FilterBoxItem>
      <FilterBoxFooter>
        <FilterFooterApplyButton onClick={onFilterButtonClick}>
          Filter
        </FilterFooterApplyButton>
        <FilterFooterResetButton onClick={onResetClick}>
          Reset
        </FilterFooterResetButton>
      </FilterBoxFooter>
    </FilterBox>
  );
};
interface TitleFilterProps {
  showFilter: number;
  searchQuery: string;
  setSearchQuery: (val: string) => void;
  onSourceChange: (val) => void;
  onDateChange: (val, val2) => void;
  onMethodChange: (val) => void;
  onXLSButtonClick: () => void;
  onCSVButtonClick: () => void;
  onFilterButtonClick: () => void;
  onResetButtonClick: () => void;
}

const TitleFilter = ({
  showFilter,
  searchQuery,
  setSearchQuery,
  onSourceChange,
  onDateChange,
  onMethodChange,
  onXLSButtonClick,
  onCSVButtonClick,
  onFilterButtonClick,
  onResetButtonClick,
}: TitleFilterProps) => {

  return (
    <Wrapper $show={showFilter > 0}>
      <Search
        value={searchQuery}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
      />

      <FilterAndButtons>
        <Popover
          overlayClassName="filter-popover-container"
          content={
            <FilterContent
              onMethodChange={onMethodChange}
              onDateChange={onDateChange}
              onFilterButtonClick={onFilterButtonClick}
              onResetButtonClick={onResetButtonClick}
              onSourceChange={onSourceChange}
            />
          }
          trigger="click"
        >
          <FilterButton>
            <span>Filter</span>
            <FilterIcon />
          </FilterButton>
        </Popover>
        
        <Export
          downloadCSV={onCSVButtonClick}
          downloadXLS={onXLSButtonClick}
        />
      </FilterAndButtons>
    </Wrapper>
  );
};

export default TitleFilter;
