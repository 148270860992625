import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../app/api";

interface Props {
    txRef?: string;
    amount?: any;
    refundType?: string;
    refundReason?: string;
    tnxRef?:string
}

export const InitiateRefund = createAsyncThunk(
    "refund/initiate",
    async (
        { txRef, amount, refundType, refundReason }: Props,
        { rejectWithValue },
    ) => {
        try {
            const response = await api.post(
                `/nucleus/refund/initiate/v2`,
                {
                    txRef,
                    amount,
                    refundType,
                    refundReason,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response.data.message,
            );
        }
    },
);
export const getATransactionDetails = createAsyncThunk(
    "refund/get-transaction",
    async (
        { tnxRef }: Props,
        { rejectWithValue },
    ) => {
        try {
            const response = await api.post(
                `nucleus/tnx/merchant/status
                `,
                {
                    tnxRef,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response.data.message,
            );
        }
    },
);

export const getAllRefunds = createAsyncThunk(
    "refund/get-all",
    async (
        {
            startDay,
            endDay,
            refundRef,
            status,
            amount,
            fundAmount,
            key,
            pageSize,
            pageNumber
        }: {
            startDay?: string;
            endDay?: string;
            status?: string;
            fundAmount?:number
            refundRef?:any;
            amount?: any;
            key: string;
            pageSize?: string,
            pageNumber?:any
        },
        { rejectWithValue },
    ) => {
        try {
            const headers = {
                "x-auth-token": key,
            };

            const response = await api.post(
                `/nucleus/refund/filter/paginated`,
                {
                    startDay,
                    endDay,
                    refundRef,
                    fundAmount,
                    status,
                    amount,
                    pageSize,
                    pageNumber,

                },
                {
                    headers: headers,
                },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response.data.message,
            );
        }
    },
);
