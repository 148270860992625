import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routesWithCloseTrue } from "../../../../utils/routesWithClose";
import { motion } from "framer-motion";
import { usePostHog } from "posthog-js/react";
import {
    LogoMenuDiv,
    UserBox,
    Wrapper,
    DropdownMenuItem,
    Content,
    DropdownMenuButton,
    Right,
    list,
    itemVariants,
    Close,
    Logo,
} from "./style";
import { ReactComponent as KlashaLogo } from "../../../../assets/icons/logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/arrow-down.svg";
import { ReactComponent as BellIcon } from "../../../../assets/icons/bell.svg";
import { ReactComponent as BackIcon } from "../../../../assets/icons/back.svg";
import { ReactComponent as UserIcon } from "../../../../assets/icons/user.svg";
import { ReactComponent as SettingIcon } from "../../assets/setting.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/icons/help.svg";
import { ReactComponent as LogoutIcon } from "../../assets/log-out.svg";
import { BusinessContextProvider } from "../../../../context/BusinessContext/BusinessContext";
import { useAppDispatch } from "../../../../app/hooks";
import {clearFormData, clearUserData} from "../../../../pages/Wallets/redux/formDataSlice";

interface Props {
    onShowSide: () => void;
    setDropdownOpen: (val: boolean) => void;
    dropdownOpen: boolean;
}

const Header = ({
    onShowSide,
    setDropdownOpen,
    dropdownOpen,
}: Props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const posthog = usePostHog();
    const [close, setClose] = useState(false);
    const { pathname } = useLocation();
    const back =
        window.location.pathname.split("/").length > 2;

    const logOut = () => {
        localStorage.clear();
        window.location.reload();
        navigate("/login");
        posthog.reset();
    };

    React.useEffect(() => {
        const isClose =
            routesWithCloseTrue.includes(pathname) ||
            location.pathname.includes("team/role/") ||
            location.pathname.includes(
                "/account-creation/",
            );
        setClose(isClose);
    }, [pathname, location.pathname]);

    const handleNavigate = () => {
        const isAccountCreationPath = location.pathname.includes("/account-creation/");

        if (isAccountCreationPath) {
            dispatch(clearFormData());
            navigate("/wallets");
        } else {
            dispatch(clearUserData());
            navigate(-1);
        }
    };


    const allPaths =
        routesWithCloseTrue.includes(pathname) ||
        location.pathname.includes("team/role/");

    return (
        <BusinessContextProvider>
            <Wrapper>
                {close && <Logo />}
                <LogoMenuDiv>
                    <button onClick={onShowSide}>
                        <MenuIcon />
                    </button>
                    <span>
                        <KlashaLogo />
                    </span>
                </LogoMenuDiv>

                <Right back={back}>
                    <p onClick={() => navigate(-1)}>
                        {allPaths ? "" : <BackIcon />}
                    </p>

                    <Content>
                        <BellIcon />
                        <UserBox
                            onClick={() =>
                                setDropdownOpen(
                                    !dropdownOpen,
                                )
                            }
                            isOpen={dropdownOpen}
                        >
                            <UserIcon />
                            <DropdownIcon />

                            <motion.ul
                                className="dropdown"
                                animate={
                                    dropdownOpen
                                        ? "open"
                                        : "closed"
                                }
                                variants={list}
                            >
                                <DropdownMenuItem
                                    variants={itemVariants}
                                    onClick={() => {
                                        navigate(
                                            "/settings",
                                        );
                                        setDropdownOpen(
                                            false,
                                        );
                                    }}
                                >
                                    <SettingIcon />
                                    <span>Settings</span>
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    variants={itemVariants}
                                    onClick={() => {
                                        navigate(
                                            "/contact",
                                        );
                                        setDropdownOpen(
                                            false,
                                        );
                                    }}
                                >
                                    <SupportIcon />
                                    <span>Support</span>
                                </DropdownMenuItem>
                                <DropdownMenuButton
                                    variants={itemVariants}
                                    onClick={logOut}
                                >
                                    <LogoutIcon />
                                    <span>Log out</span>
                                </DropdownMenuButton>
                            </motion.ul>
                        </UserBox>
                    </Content>
                </Right>

                {dropdownOpen && (
                    <div
                        className="overlay"
                        onClick={() =>
                            setDropdownOpen(false)
                        }
                    />
                )}
                {close && (
                    <Close onClick={handleNavigate} />
                )}
            </Wrapper>
        </BusinessContextProvider>
    );
};

export default Header;