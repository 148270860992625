import React, {
    useState,
    useEffect,
    useContext,
} from "react";
import { usePostHog } from "posthog-js/react";
import {
    WalletSummaryCard,
    SelectedDropdownOption,
    ButtonsDiv,
    WalletSummaryCardIcon,
    WalletSummarySection,
    Wrapper,
    Heading,
    Title,
    TermsWrapper,
    TernsContent,
} from "./style";
import CustomModal from "../../../../components/common/CustomModal";
import { getCurrency } from "country-currency-map";
import CustomSelect from "../../../../components/common/CustomSelect";
import { Select } from "antd";
import { Button } from "../../../../components/common/Button";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import TransactionsTable from "../../components/TransactionsTable";
import CurrencyFlag from "../../components/CurrencyFlag";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../app/hooks";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import { CURRENCIES } from "../../utils";
import { formatCurrencyCodeAmount } from "../../../../utils/currency";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { getBusinessText } from "../../../../utils/UpdateBusinessType";
import { createNewWallet } from "../../api";
import Loader from "../../../../components/common/Loader";
import {allVirtualAccounts, allWallets} from "../../redux/slice";
import { selectUserRoleAndPermissions } from "../../../Settings/Team/redux/slice";
import CheckBox from "../../../../components/common/CheckBox/CheckBox";

const { Option } = Select;

const AllWallets = () => {
    const posthog = usePostHog();
    const [showModal, setShowModal] = useState(false);
    const [state, setState] = useState({
        openModal: null,
        acceptedTandC: null
    })
    const [currenciesData, setCurrenciesData] = useState(
        [],
    );
    const dispatch = useAppDispatch();

    const { wallets } = useAppSelector(
        (state) => state?.wallets,
    );
    const { role, permissions } = useAppSelector(
        selectUserRoleAndPermissions,
    );
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );

    const hasCreateWalletPermission = permissions?.["wallet"]?.includes("CREATE_WALLET");
    const [newCurrency, setNewCurrency] = useState(null);
    const [loading, setLoading] = useState(false);
    const { userId, businessId } = useAppSelector(
        (state) => state.users,
    );
    const { business } = useAppSelector(
        (state) => state.settings,
    );
    

    const businessType = getBusinessText(
        business?.organizationType,
    );
    const { onShowAlert } = useContext(SuccessAlertContext);
    const navigate = useNavigate();

    const closeModal = () => {
        setShowModal(false);
        setNewCurrency(null);
        handleDataUpdate("openModal", false)
    };

    const handleDataUpdate = (field, value) => {
        setState((prev) => ({
            ...prev,
            [field]: value,
        }));
    };


    const handleReadTandC = () => {
        handleDataUpdate("openModal", false);
        setShowModal(true);
    };
    
    
    const createWallet = async () => {
        if(hasCreateWalletPermission || role === "OWNER"){
            if (businessId && business?.approved) {
                if (
                    businessType === "Starter Business" &&
                    newCurrency !== "NGN"
                ) {
                    onShowErrorAlert(
                        "",
                        "Cannot create this wallet for Starter Business. Upgrade to create wallet.",
                    );
                } else {
                    if (newCurrency) {
                        setLoading(true);
                        try {
                            await createNewWallet({
                                businessId,
                                userId,
                                currency: newCurrency,
                            }).then(async() => {
                                posthog?.capture(
                                    "create_wallet_b2b",
                                    {
                                        status: "success",
                                        currency: newCurrency,
                                    },
                                );
                                dispatch(allWallets({ businessId }));
                                onShowAlert(
                                    "Wallet created successfully",
                                );
                                setShowModal(false);
                            });
                        } catch (error) {
                            onShowErrorAlert(
                                "",
                                error?.response?.data?.message,
                            );
                            
                            posthog?.capture(
                                "create_wallet_b2b",
                                {
                                    status: "failure",
                                    failure_reason:
                                        "wallet creation failed",
                                    currency: newCurrency,
                                },
                            );
                            setLoading(false);
                        } finally {
                            setLoading(false);
                        }
                    } else {
                        return;
                    }
                }
            } else {
                onShowErrorAlert(
                    "",
                    "Business awaiting approval.",
                );
            }
        }else{
            onShowErrorAlert("", "Access is denied")
        
        }
        
    };

    const redirectToCurrency = (data) => {
        navigate(`/wallets/${data.currency}`);
        return;
    };

    useEffect(() => {
        if (businessId) {
            dispatch(allWallets({ businessId }));
            dispatch(allVirtualAccounts({businessId}))
        }
    }, [businessId]);

    

    useEffect(() => {
        const usersWalletCurrencies = wallets?.map(
            (wallet) => wallet.currency,
        );
        const allPossibleCurrencies =
            Object.keys(CURRENCIES);

        const allowedCurrencies =
            allPossibleCurrencies.filter(
                (curr) =>
                    !usersWalletCurrencies?.includes(curr),
            );

        const curr = allowedCurrencies.map((pay) => {
            const regex = /\((.*?)\)/i;
            let theFullName = getCurrency(pay)?.name;
            theFullName = theFullName
                ?.replace(regex, "")
                .trimEnd();

            if (pay === "GHS") {
                theFullName = "Ghanaian Cedi";
            }

            return {
                value: pay,
                fullName: theFullName,
                label: (
                    <SelectedDropdownOption>
                        <li
                            className={`currency-flag currency-flag-${pay?.toLowerCase()}`}
                        />{" "}
                        {pay} - {theFullName}
                    </SelectedDropdownOption>
                ),
            };
        });

        setCurrenciesData(curr);
    }, [wallets]);

    const currencies = new Set(wallets?.map((wallet) => wallet?.currency));
    const hasBothCurrencies =
      currencies?.has("NGN") && currencies?.has("USD") && currencies?.has("ZAR") && currencies.has("KES");

      const handleCheckBoxChange = (e) => {
        handleDataUpdate("acceptedTandC", e.target.checked);
    };
    
    return (
        <>
            <Heading>Wallet</Heading>
            <WalletSummarySection>
                {wallets?.map(
                    (wallet) =>
                        CURRENCIES[wallet.currency] && (
                            <WalletSummaryCard
                                key={wallet.currency}
                                onClick={() =>
                                    redirectToCurrency(
                                        wallet,
                                    )
                                }
                                currency={wallet?.currency}
                            >
                                <Wrapper>
                                    <p>
                                        <CurrencyFlag
                                            currency={
                                                wallet.currency
                                            }
                                        />
                                        <span>
                                            {
                                                CURRENCIES[
                                                    wallet
                                                        .currency
                                                ].name
                                            }
                                        </span>
                                    </p>
                                </Wrapper>
                                <h4 className="amount">
                                    <span />
                                    {formatCurrencyCodeAmount(
                                        wallet?.currency,
                                        wallet?.availableBalance.toFixed(
                                            2,
                                        ),
                                    )}
                                </h4>
                            </WalletSummaryCard>
                        ),
                )}

                {!hasBothCurrencies && (
                    <WalletSummaryCard
                        onClick={() => setShowModal(true)}
                    >
                        <WalletSummaryCardIcon>
                            <PlusIcon />
                        </WalletSummaryCardIcon>
                        <h4>Create new wallet</h4>
                    </WalletSummaryCard>
                )}
            </WalletSummarySection>

            <TransactionsTable />

            {showModal && (
                <CustomModal
                    isModalVisible={showModal}
                    onClose={closeModal}
                    width="464px"
                >
                    <Title>
                        Create new currency wallet
                    </Title>
                    <CustomSelect
                        placeholder="Select Currency"
                        disableLabel
                        dropdownClassName="dropDownBox"
                        $width="100%"
                        $height="48px"
                        value={newCurrency}
                        onChange={setNewCurrency}
                    >
                        {currenciesData.map((cur) => (
                            <Option
                                value={cur.value}
                                label={cur.label}
                                key={cur.value}
                            >
                                <div className="demo-option-label-item">
                                    <li
                                        className={`currency-flag currency-flag-${cur.value?.toLowerCase()}`}
                                    />
                                    <span className="currency-drop-down">
                                        <span>
                                            {cur.fullName}
                                        </span>
                                    </span>
                                </div>
                            </Option>
                        ))}
                    </CustomSelect>

                   {newCurrency === "USD" && (
                     <TermsWrapper>
                     <CheckBox  onChange={handleCheckBoxChange}/>
                 <p>I have read and understood the <span onClick={() => handleDataUpdate("openModal", true)}>
                 terms and conditions</span></p>
                 </TermsWrapper>
                   )}



                    <ButtonsDiv>
                        <Button
                            label="Cancel"
                            type="button"
                            theme="alternate"
                            height="40px"
                            fontSize="12px"
                            onClick={closeModal}
                        />
                        <Button
                            label={`${
                                loading
                                    ? "loading..."
                                    : "Proceed"
                            }`}
                            type="submit"
                            theme="primary"
                            height="40px"
                            fontSize="12px"
                            onClick={createWallet}
                            disabled={loading  || (newCurrency === "USD" && !state.acceptedTandC)}
                        />
                    </ButtonsDiv>

                </CustomModal>
            )}

            {state.openModal && (
                <CustomModal
                isModalVisible={state.openModal}
                onClose={handleReadTandC}
                width="480px"
              >
                  <TernsContent>
                <h1>Terms & Conditions for USD Wallet & Account Service</h1>
                <p>
                  Welcome to our USD Wallet service. By creating and using a USD Wallet, you
                  agree to the following terms and pricing structure: <span>
                  USD Wallet Creation and Usage
                      </span>:
                </p>
              
                <ul>
                  <li>
                    <strong>Wallet Creation:</strong> Creating a USD Wallet is free of charge.
                    No initial setup fee is required to activate your wallet.
                  </li>
                  <li>
                    <strong>Inbound Fees:</strong> Funding your USD Account incurs an inbound fee based on the amount transferred, as detailed below:
                    <ul>
                      <li>a. Less than $5,000,000: 0.45%</li>
                      <li>b. $5,000,001 to $15,000,000: 0.39%</li>
                      <li>c. $15,000,001 to $30,000,000: 0.32%</li>
                      <li>d. Above $30,000,001: 0.24%</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Monthly Account Fees:</strong>
                    <p>
                      Minimum Monthly Fee: This fee equals £2,000 and applies if combined
                      transaction and service fees fall below this amount.
                    </p>
                  </li>
                  <li>
                    <strong>Other Transaction Fees:</strong>
                    <ul>
                      <li>a. Transfer Fee (SWIFT): $45 per transaction.</li>
                      <li>
                        b. Minimum Balance: Your USD Wallet must maintain a minimum balance of
                        $2,500 to avoid service interruptions or additional fees.
                      </li>
                      <li>
                        c. Value-Added Tax (VAT): All fees do not include VAT. Based on your
                        location and applicable laws, applicable VAT will be charged.
                      </li>
                    </ul>
                  </li>
                </ul>
              
                <h3>General Terms</h3>
                <ul>
                  <li>
                    <strong>Changes to Terms:</strong> We reserve the right to modify these
                    terms or fees at any time. You will be notified of changes through your
                    registered email or visible posting on our platform.
                  </li>
                  <li>
                    <strong>Service Usage:</strong> The USD Wallet is intended for business
                    transactions. Misuse or fraudulent activities may result in the suspension
                    or termination of the wallet service.
                  </li>
                  <li>
                    <strong>Data Protection:</strong> We adhere to stringent data protection
                    laws to ensure the confidentiality and security of your personal and
                    transaction data.
                  </li>
                </ul>
              
                <p>
                  By ticking the checkbox, you acknowledge that you have read, understood, and
                  agreed to the above mentioned terms.
                </p>
              
                </TernsContent>
              </CustomModal>
            )}


            <Loader isLoading={loading}/>
        </>
    );
};

export default AllWallets;
