import React from 'react';
import { Wrapper } from './styles';

interface Props{
    placement: 'left' | 'right';
    open: boolean;
    title: string;
    onClose: any;
    children?: React.ReactNode;
}


const DrawerContainer = ({placement, open, title, onClose, children}: Props) => {
  return (
    <Wrapper placement={placement} open={open} title={title} onClose={onClose}>
        {children}
    </Wrapper>
  )
}

export default DrawerContainer;