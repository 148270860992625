import React from "react";
import { ReactComponent as AccountCreationIcon } from "../../../../assets/account-creation-icon.svg";
import { Flex, PendingWrapper } from "./style";



const PendingAccount = () => {
    return (
        <PendingWrapper>
            <Flex>
                <AccountCreationIcon />

               <div>
               <h1>
                    Your USD account details are on the way!
                </h1>
            <p>
                We are currently reviewing your detials. You
                will get an update within 24 - 72 hours.
            </p>
               </div>
            </Flex>
        </PendingWrapper>
    );
};

export default PendingAccount;
