import React from "react";
import PendingAccount from "./PendingAccount";
import RequestAccount from "./RequestAccount";

interface Props {
    status: "pending" | "completed" | "failed" | "not-found";
    handleClick: () => void;
}

const AccountWrapper = ({ status, handleClick }: Props) => {
    
    let accountComponent;

    switch (status) {
        case "failed":
            accountComponent = (
                <RequestAccount
                    handleClick={handleClick}
                    accountType={status}
                />
            );
            break;
        case "pending":
            accountComponent = (
                <PendingAccount
                />
            );
            break;
        default:
            accountComponent = null;
            break;
    }

    return <div>{accountComponent}</div>;
};


export default AccountWrapper;