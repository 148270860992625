import { Input } from "antd";
import styled from "styled-components";

interface WrapperProps {
    $show: boolean;
  }

export const Wrapper = styled.div<WrapperProps>`
  font-family: "Inter";
  display: ${(props: { $show: boolean }) => (props.$show ? "block" : "none")};
  @media (min-width: 768px) {
    display: ${(props: { $show: boolean }) => (props.$show ? "block" : "none")};
    align-items: center;
    justify-content: space-between;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
  font-family: "Inter";
  
  @media (min-width: 768px) {
    justify-content: flex-start;
    width: unset;
    gap: 12px;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  padding: 12px 16px;
`;


export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 94px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #000000;
  color: #000000;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 15.6px;
    margin-right: 8px;
  }
`;

export const RequestButton = styled.button`
  width: 128px;
  height: 40px;
  background: #000000;
  border-radius: 6px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  :disabled {
    background-color: #f1f1f1;
    color: #717070;
  }
`;

export const FilterBox = styled.div`
  padding: 32px 24px;
  position: absolute;
  z-index: 9999;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  width: 333px;
  right: -16px;
  bottom: unset;
  top: calc(100% + 42px);
  font-family: "Inter";

  @media (max-width: 768px) {
    left: -30px;
  }
`;

export const FilterBoxItem = styled.div`
  margin-bottom: 24px;
`;
export const FilterBoxItemTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #222223;
  margin-bottom: 8px;
`;

export const FilterBoxFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterFooterResetButton = styled.button`
  width: 78px;
  height: 40px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const FilterFooterApplyButton = styled.button`
  width: 69px;
  height: 40px;
  background: #000000;
  border-radius: 6px;
  color: #ffffff;
`;
