import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { usePostHog } from 'posthog-js/react'
import {
  Section,
  StyledCurrencyInput,
  CustomSelect,
  SelectOption,
  Wrapper,
} from "./style";
import { ReactComponent as RateIcon } from "../../../assets/icons/transfer.svg";
import { ReactComponent as DropdownIcon } from "../../../assets/icons/arrow-down.svg";
import { Button } from "../../common/Button";
import "../../../components/common/CustomSelect/style.css";
import { Select } from "antd";
import { getRate } from "../../../pages/ExchangeRate/rateAPI";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { useAppSelector } from "../../../app/hooks";
import { selectUserRoleAndPermissions } from "../../../pages/Settings/Team/redux/slice";
const { Option } = Select;

const Conversion = ({
  destinationCurrencyOptions,
  sourceCurrencyOptions,
  setLoading,
}: {
  destinationCurrencyOptions: any[];
  sourceCurrencyOptions: any[];
  setLoading:(arg: boolean) => void;
}) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { business } = useAppSelector((state) => state.settings);
  useState(null);
  const { onShowAlert: onShowErrorAlert } = useContext(
      NetworkErrorAlertContext,
  );

  const [sourceCurrency, setSourceCurrency] = useState("NGN");
  const [destinationCurrency, setDestinationCurrency] = useState("USD");
  const [sourceValue, setSourceValue] = useState('');
  const [destinationValue, setDestinationValue] =
    useState('');
  const [exchangeRate, setExchangeRate] = useState(null);
  const { onShowAlert } = useContext(NetworkErrorAlertContext);
  const { role, permissions } = useAppSelector(
    selectUserRoleAndPermissions,
);

const hasWirePermission = permissions?.[
    "wire"
]?.includes("EXPORT_COLLECTION_STATEMENT");

  const handleSendWire = () => {
    if(hasWirePermission || role === "OWNER"){
        if(business?.approved){
            navigate('/wire/send')
            posthog?.capture("initiate_wire_b2b", { status: "success"})
          } else {
              onShowErrorAlert(
                  "",
                  "Business awaiting approval.",
              );
            posthog?.capture("initiate_wire_b2b", { status: "failure", failure_reason: "Business awaiting approval"})
          }
    }else{
        onShowErrorAlert("", "Access is denied")
    
    }
   
  }

  const onDestinationValueChange = (value: string) => {
    setDestinationValue(value);

    if (exchangeRate) {
      const newSourceVal = (+value / exchangeRate).toFixed(3);
      const checkNaN = isNaN(+newSourceVal) ? '' : newSourceVal;
      setSourceValue(checkNaN);
    }
  };

  const onSourceValueChange = async (value: string) => {
   setSourceValue(value);
   
    if (exchangeRate) {
      let newDestinationVal = (exchangeRate * +value).toFixed(3);
      setDestinationValue(isNaN(+newDestinationVal) ? '' : newDestinationVal);
    } else {
      const rate = await getExchangeRateValue(
        destinationCurrency,
        sourceCurrency
      );
      let newDestinationVal = (rate * +value).toFixed(2);
      setDestinationValue(isNaN(+newDestinationVal) ? '' : newDestinationVal);
    }
  };

  const onDestinationCurrencyChange = async (value: string) => {
    setDestinationCurrency(value);

     if (sourceValue) {
      const newExRate = await getExchangeRateValue(
        value,
        sourceCurrency,
      );

      let newDestinationVal = (newExRate * +sourceValue).toFixed(2);
      setDestinationValue(newDestinationVal);
    }
  };

  const onSourceCurrencyChange = async (value: string) => {
    setSourceCurrency(value);

    if (sourceValue) {
      const newExRate = await getExchangeRateValue(
        destinationCurrency,
        value);

      let newSourceVal = (newExRate * +sourceValue).toFixed(2);
      setDestinationValue(newSourceVal);
    } 
  };

  const getExchangeRateValue = async (destCur:string, sourceCur:string) => {
    let exRate: number;

    const payload = {
      sourceCurrency: sourceCur,
      amount: 1,
      destinationCurrency: destCur,
      provider: "butter",
    };

    try {
      setLoading(true);
      const ExcData = await getRate(payload);

      const excAmount = ExcData || 0;
      setExchangeRate(excAmount);
      exRate = excAmount;
      setLoading(false);
    } catch (e) {
      exRate = 0;
      let msg: string;
      const errObj = e?.response?.data;

      if(typeof errObj.error === "string") {
        msg = errObj.error;
      } else if( typeof errObj.message === "string"){
        msg = errObj.message;
      } else {
        msg = "Failed"
      }
      setLoading(false);
      onShowAlert(errObj, msg);
    }
    
    return exRate;
  };

  return (
    <Wrapper>
      <Section>
        <StyledCurrencyInput
          placeholder="Amount"
          value={sourceValue}
          decimalsLimit={2}
          onValueChange={(value) => onSourceValueChange(value)}
        />
        <CustomSelect
          dropdownMatchSelectWidth={false}
          placement="bottomLeft"
          value={sourceCurrency}
          onChange={onSourceCurrencyChange}
          suffixIcon={<DropdownIcon />}
          popupClassName="filter-dropdown"
        >
        {sourceCurrencyOptions?.length ? sourceCurrencyOptions.map((curr, i) => (
          <Option key={curr.value} value={curr.value}>
              <SelectOption>
                <span className={`currency-flag currency-flag-${curr.value.toLowerCase()}`} />
                <span>{curr.value}</span>
              </SelectOption>
          </Option>
         ) ) : null}
        </CustomSelect>
      </Section>

      <RateIcon />

      <Section>
        <StyledCurrencyInput
          placeholder="Amount"
          value={destinationValue}
          decimalsLimit={2}
          onValueChange={(value) => onDestinationValueChange(value)}
        />
        <CustomSelect
          dropdownMatchSelectWidth={false}
          placement="bottomLeft"
          value={destinationCurrency}
          onChange={onDestinationCurrencyChange}
          suffixIcon={<DropdownIcon />}
          popupClassName="filter-dropdown"
        >
          {destinationCurrencyOptions?.length ? destinationCurrencyOptions.map((curr, i) => (
            <Option key={curr.value} value={curr.value}>
              <SelectOption>
                <span className={`currency-flag currency-flag-${curr.value.toLowerCase()}`} />
                <span>{curr.value}</span>
              </SelectOption>
            </Option>
           ) ) : null}
        </CustomSelect>
      </Section>

      <Button
        label="Send wire"
        theme="secondary"
        height="40px"
        fontSize="12px"
        onClick={handleSendWire}
      />
    </Wrapper>
  );
};

export default Conversion;
