import styled from "styled-components";
import { Steps } from "antd";

export const Wrapper = styled.div`
  font-family: "Inter";
  width: 100%;

  .btn {
    margin-top: 30px;
  }

  @media (min-width: 768px){
    width: 650px;
    margin: 0 auto;
  }
`;

export const StepContent = styled.section`
  width: 100%;
  margin-top: 30px;

  @media (min-width: 768px){
    width: 80%;
    margin: 60px auto 0 auto;
  }
`;

export const Title = styled.h3`
  font-family: "Athletics";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px; 
  letter-spacing: -0.56px;
  color: #000000;
  text-align: center;
`;

export const CurrentStep = styled.div`
  margin: 40px 0px 10px;  

  & > * {
    width: 100%;
  }
`;

export const StepContainer = styled.div`
  margin-top: 40px;
`;

export const Stepper = styled(Steps)`
  @media (max-width: 767px) {
    display: none;
  }

  .ant-steps-item-icon {
    display: none;
  }

  .ant-steps-item-title {
    font-size: 14px;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #ef2c5a;
  }
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 6px;
  justify-content: center;
`;
