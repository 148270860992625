import TransactionStatus from "../../components/transactions/components/TransctionStatus";
import dayjs from 'dayjs';
import exportFromJSON from "export-from-json";


export const tableColumns = [
    {
        title: "Payout ID",
        dataIndex: "payoutid",
        key: "payoutid",
    },
    {
        title: "Bank",
        dataIndex: "accBank",
        key: "accBank",
    },
    {
        title: "Account name",
        dataIndex: "accName",
        key: "accName",
    },
    {
        title: "Account number",
        dataIndex: "accNo",
        key: "accNo",
    },
    {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: "Request date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text: string) => <TransactionStatus text={text} />,
    },
];



export const allCollectionsColumn = [

    {
        title: "Reference",
        dataIndex: "ref",
        key: "ref",
    },
    {
        title: "Merchant amount",
        dataIndex: "merchant_amount",
        key: "merchant_amount",
    },
    {
        title: "Payer amount",
        dataIndex: "payer_amount",
        key: "payer_amount",
    },
    {
        title: "Amount less fees",
        dataIndex: "amount_less",
        key: "amount_less",
    },
    
    {
        title: "Settlement currency",
        dataIndex: "currency",
        key: "currency",
    },
    {
        title: "Settlement amount",
        dataIndex: "settlement_amount",
        key: "settlement_amount",
    },
   
    {
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text: string) => (
          <TransactionStatus text={text} />
        ),
      },
    

]






export const getExportCollectionsBreakDown = async (data, currencyCode) => {
    return data?.transactionSplit.map(
        (item) => ({
            key: item.transaction.id,
            ref: item?.transaction.tnxRef,
            merchant_amount:
                item.transaction.sourceCurrency +
                " " +
                item.transaction.sourceAmount,
            payer_amount:
                item.transaction.currency +
                " " +
                item.transaction.chargedAmount,
            currency:
                item.transaction.sourceCurrency || "--",
            source:
                currencyCode +
                " " +
                (
                    item.transaction.amount / 100 ||
                    "0.00"
                ).toLocaleString(),
            amount_less:
                item.foreignCurrency +
                " " +
                item.foreignAmount,
            paymentType: item.transaction.paymentType,
            settlement_amount:
                item.transaction.sourceCurrency +
                " " +
                item.transaction.settledAmount,
            amount:
                currencyCode +
                " " +
                Number(
                    item.transaction.sourceAmount,
                ).toLocaleString(),
            date: dayjs(
                item.transaction.createdAt,
            ).format("DD.MM.YYYY HH:mm"),
            settled:
                currencyCode +
                " " +
                (
                    item.transaction.settledAmount ||
                    "0.00"
                ).toLocaleString(),
            local:
                currencyCode +
                " " +
                (item.amount / 100).toLocaleString(),
            less:
                item.foreignCurrency +
                " " +
                item.foreignAmount,
            reference:
                item.transaction.tnxRef || "No Ref",
            rate:
                item.transaction.settlementRate ||
                "0.00",
        }),
    );
};

export const exportToCSV = async (downloadedData, currencyCode) => {
    const data = await getExportCollectionsBreakDown(downloadedData, currencyCode);
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({
        data,
        fileName: "Collections transaction",
        exportType,
    });
};

export const exportToXLS = async (downloadedData, currencyCode) => {
    const data = await getExportCollectionsBreakDown(downloadedData, currencyCode);
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({
        data,
        fileName: "Collections Breakdown",
        exportType,
    });
};


export const getExportPayoutData = async (exportCollectionData) => {    
    return exportCollectionData?.transactionSplit.map(
        (item) => ({
            ref: 
                item.transaction.tnxRef,
            transaction_id: item.transaction.tnxRef,
            merchant_amount: item.transaction.sourceCurrency + " " +  item.transaction.sourceAmount,
            payer_amount:  item.transaction.currency + " " + item.transaction.chargedAmount,
            currency:  item.transaction.sourceCurrency,
            rate: item.transaction.rate,
            date:
                dayjs(
                    item.transaction.createdAt,
                ).format("DD.MM.YYYY - HH:mm") || "--",
            settlement_amount:
                    item.transaction.sourceCurrency + " " +
                    item.transaction.settledAmount,
            amount_less:
                item.foreignCurrency +
                " " +
                item.foreignAmount,
            paymentType: item.transaction.paymentType,
        }),
    );
};


const getExportData = async (payoutRequests, accountMap) => {
    return payoutRequests?.map((settle) => ({
        "Payout ID":
            settle?.merchantWithdrawRequest.requestId,
        Status: settle?.merchantWithdrawRequest
            ?.payoutStatus,
        Bank:
            accountMap[
                settle?.merchantWithdrawRequest
                    ?.bankAccount
            ]?.accBank ?? "-",
        "Account number":
            accountMap[
                settle?.merchantWithdrawRequest
                    ?.bankAccount
            ]?.accNo ?? "-",
        "Account name":
            accountMap[
                settle?.merchantWithdrawRequest
                    ?.bankAccount
            ]?.accName ?? "-",
        Date: settle?.merchantWithdrawRequest?.createdAt,
        Amount: settle?.merchantWithdrawRequest?.amount,
    }));
};

export const exportToXLSBalance = async (exportCollectionData, activeToggle, payoutRequests, accountMap) => {
    let data;
    if (activeToggle === "Payout") {
        data = await getExportData(payoutRequests, accountMap);
    } else {
        data = await getExportPayoutData(exportCollectionData);
    }

    const exportType = exportFromJSON.types.xls;
    const fileName =
        activeToggle === "Payout"
            ? "payouts"
            : "Collections";

    exportFromJSON({
        data,
        fileName,
        exportType,
    });
};

export const exportToCSVBalance = async (exportCollectionData, activeToggle, payoutRequests, accountMap) => {
    let data;
    
    if (activeToggle === "Payout") {
            data = await getExportData(payoutRequests, accountMap);
        } else {
            data = await getExportPayoutData(exportCollectionData);
        }
    
        const exportType = exportFromJSON.types.csv;
        const fileName =
            activeToggle === "Payout"
                ? "payouts"
                : "Collections";
    
        exportFromJSON({
            data,
            fileName,
            exportType,
        });
    
};

