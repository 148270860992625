import React, { useEffect, useState, useCallback, useContext } from "react";
import { SavePaymentData } from "../../../../redux/formDataSlice";
import InputAndSelect from "../../../../../../components/common/InputAndSelect";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { CurrencyWrapper, RateBox } from "../styles";
import { getBassFee, getExchangeRate } from "../../../../redux/slice";
import Loader from "../../../../../../components/common/Loader";
import { Button } from "../../../../../../components/common/Button";
import debounce from 'lodash.debounce';
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";

interface Wallet {
    currency?: string;
    setStep: (data: number) => void;
    setAmount?: (data: number) => void;
}

interface UserData {
    amount: number;
    convertedAmount: number;
    loading: boolean;
    fees: number;
    currency: string
    buyingRate: number;
    rate: number;
    convertedRate: any
}

const initialState: UserData = {
    amount: null,
    convertedAmount: null,
    loading: true,
    fees: null,
    currency: '',
    buyingRate: null,
    rate: null,
    convertedRate: null
};

const SameCurrencyPayout = ({ setStep, setAmount }: Wallet) => {
    const { wallets, loading } = useAppSelector(
        (state) => state?.wallets || {},
    );
    const savedUserData = useAppSelector(
        (state) => state?.formData?.userData || initialState,
    );
    const [userData, setUserData] = useState<UserData>(savedUserData);
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext
      );
    
    const dispatch = useAppDispatch();

    const filteredWallets = wallets as Wallet[];

    const uniqueCurrencies = Array.from(
        new Set(
            filteredWallets?.map(
                (wallet) => wallet.currency,
            ),
        ),
    );

    const options = uniqueCurrencies.map((currency) => ({
        label: currency,
        value: currency,
    }));

    const usdOption = options?.filter(
        (option) => option.value === "USD",
    );

    const handleAmountChange = (enteredAmount: any) => {
        setUserData((prevData) => ({
            ...prevData,
            amount: enteredAmount < 1 ? null : enteredAmount,
        }));
    };




    const debouncedFetchBassFee = useCallback(debounce(async (amount: number, destinationCurrency: string) => {
        const usdCurrency = usdOption.find((currency) => currency.label === "USD")?.value || "";                
        if (amount !== null && amount > 0) {
            const action = await dispatch(getBassFee({ currency: "USD" }));
            const { fees  } = action.payload;
            const formattedRate = (1 / userData.rate).toFixed(6);
            const output = `${formattedRate} (${userData.rate})`            
            const exchangeRate = await dispatch(
                getExchangeRate({
                    destinationCurrency: destinationCurrency,
                    sourceCurrency: "USD",
                    provider: "BAAS",
                    source: "checkout",
                    amount: amount,
                }),
            );             

                if(getExchangeRate.fulfilled.match(exchangeRate) && getBassFee.fulfilled.match(action)){
                    const { rate, buyingRate} = exchangeRate.payload.data                              
                    await setUserData((prevData) => ({
                        ...prevData,
                        fees: fees,
                        convertedAmount:  Number((prevData.amount * rate) + fees),
                        currency: usdCurrency,
                        loading: false,
                        rate: rate,
                        buyingRate: buyingRate,
                        convertedRate: output
                        
                    }));
                    
                }
            if (getBassFee.fulfilled.match(action)) {
                setUserData((prevData) => ({
                    ...prevData,
                    fees: fees,
                    currency: usdCurrency,
                    loading: false,
                    
                }));
            } else if (getBassFee.pending.match(action)) {
                setUserData((prevData) => ({
                    ...prevData,
                    loading: true
                }));
            }
            else if(getBassFee.rejected.match(action) || getExchangeRate.rejected.match(exchangeRate)){
                setUserData((prevData) => ({
                    ...prevData,
                    fees: null,
                    convertedAmount: null,
                    currency: null,
                    loading: false,
                    
                })); 
                onShowErrorAlert("", "Something went wrong. Please try again");
            }
        } else {
            setUserData((prevData) => ({
                ...prevData,
                fees: null,
                convertedAmount: null,
                loading: false
            }));
        }
    }, 1000), [dispatch]);

    useEffect(() => {
        debouncedFetchBassFee(userData.amount, "USD");
    }, [userData.amount, debouncedFetchBassFee]);

    useEffect(() => {
        dispatch(SavePaymentData(userData));
    }, [userData, dispatch]);

    const handleNextStep = () => {
        dispatch(SavePaymentData(userData));
        setStep(2);
    };
    
    
    return (
        <CurrencyWrapper>
            <Loader isLoading={loading} />
            <div className="same-wallet">
                <InputAndSelect
                    selectValue="USD"
                    options={usdOption}
                    placeholder="Amount"
                    amountValue={userData.amount}
                    onAmountChange={handleAmountChange}
                    width="100%"
                />
            </div>

            {userData.convertedAmount !== null && (
                <div className="rate-box">
                    <RateBox>
                        <span className="fee-box">
                            +
                            <span>
                                {`USD ${userData.fees}`}
                            </span>
                        </span>
                        <span>Processing fee</span>
                    </RateBox>
                </div>
            )}

            {userData.convertedAmount !== null && (
                <div className="same-wallet">
                    <InputAndSelect
                        selectValue="USD"
                        options={usdOption}
                        amountValue={userData.convertedAmount.toFixed(4)}
                        width="100%"
                    />
                </div>
            )}
            <Button
                onClick={handleNextStep}
                width="500px"
                height="48px"
                label="Continue"
                className="btn"
                disabled={loading || userData.fees === null || userData.rate === null}
            />
        </CurrencyWrapper>
    );
};

export default SameCurrencyPayout;